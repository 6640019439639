import React from "react";
import axios from 'axios'
import { Modal } from "antd";
import "./index.css";


const { info } = Modal;


class WxArticle extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            WxArticle: [],
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
       
        this.initData()
    }

    initData() {
        axios({
            method: "get",
            url: "/Index/index/article",
          
        }).then((res) => {
            this.setState({
                WxArticle: res.data.data.articleList,
            })
        });
    }


    nextItem(item: any) {
        this.props.history.push(`/WxArticleDetail/${item.id}`);
    }

    render() {
        let { WxArticle } = this.state
        return (
            <div className="WxArticle">
                {/*  */}
                <div
                    id="carouselExampleInterval"
                    className="carousel slide h-120"
                    data-bs-ride="carousel"
                >
                    <div className="carousel-inner h-120">
                        <div
                            className={`carousel-itemx`}
                            data-bs-interval="3000"
                        >
                            <div className="group relative" style={{ height: "100%" }}>
                                <div
                                    style={{ height: "100%" }}
                                    className="relative w-full h-400 bg-white overflow-hidden group-hover:opacity-75 sm:aspect-w-2 sm:aspect-h-1 sm:h-64 lg:aspect-w-1 lg:aspect-h-1"
                                >
                                    <img
                                        src={'https://huayunjd.oss-cn-hangzhou.aliyuncs.com/test/news.png'}
                                        alt=""
                                        style={{ height: "400px", }}
                                        className="w-full object-center object-cover"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/*  */}
                <div className="WxArticle_content">
                    {
                        (WxArticle && WxArticle.length > 0 ? WxArticle : []).map((item, index) => {
                            return (<div className="WxArticle_item" key={index} onClick={this.nextItem.bind(this, item)}>
                                {/* <div className="WxArticle_item_img">
                                    <img src={item.pic} className="" alt="" />
                                </div> */}
                                <div className="WxArticle_item_info">
                                    <span className="WxArticle_item_info_title">{item.title}</span>
                                    <span className="WxArticle_item_info_text">{item.title_info}</span>
                                    <span className="WxArticle_item_info_date">{item.create_time}</span>
                                </div>
                            </div>)
                        })
                    }
                </div>
            </div>
        );
    }
}

export default WxArticle;
