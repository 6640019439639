import React from "react";
import axios from 'axios'
import { Modal } from "antd";
import "./index.css";

import videoPlay from "../../assets/images/icon_play@2x.png";

const { info } = Modal;


class VideoList extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            videoList: [],
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.initData()
    }

    showModal = (item) => {
        info({
            closable: true,
            width: 800,
            icon: null,
            okText: '确定',
            content: (<div>
                <video
                    src={item.video_url}
                    className="w-full"
                    style={{
                        height: "400px",
                    }}
                    autoPlay
                    controls
                ></video>
            </div>),
        });
    }

    initData() {
        axios({
            method: "get",
            url: "/Index/index/getVideo",
        }).then((res) => {
            this.setState({
                videoList: res.data.data.list,
            })
        });
    }

    sectionStyle(Background) {
        return {
            backgroundImage: "url(" + { Background } + ")"
        }
    }



    render() {
        let { videoList } = this.state
        return (
            <div className="VideoList">
                {/*  */}
                <div
                    id="carouselExampleInterval"
                    className="carousel slide h-120"
                    data-bs-ride="carousel"
                >
                    <div className="carousel-inner h-120">
                        <div
                            className={`carousel-itemx`}
                            data-bs-interval="3000"
                        >
                            <div className="group relative" style={{ height: "100%" }}>
                                <div
                                    style={{ height: "100%" }}
                                    className="relative w-full h-400 bg-white overflow-hidden group-hover:opacity-75 sm:aspect-w-2 sm:aspect-h-1 sm:h-64 lg:aspect-w-1 lg:aspect-h-1"
                                >
                                    <img
                                        src={'https://huayunjd.oss-cn-hangzhou.aliyuncs.com/test/2.png'}
                                        alt=""
                                        style={{ height: "400px", }}
                                        className="w-full object-center object-cover"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/*  */}
                <div className="VideoList_content">
                    <p style={{ margin: "15px 0px" }}>视频中心</p>
                    <div className="VideoList_content_box">
                        {
                            (videoList && videoList.length > 0 ? videoList : []).map((item, index) => {
                                return (<div className="VideoList_item_box" key={index} onClick={this.showModal.bind(this, item)}>
                                    <div className="VideoList_item" style={{
                                        backgroundImage: `url(${item.video_img})`
                                    }}>
                                        {/* <img src={item.video_img} alt="" /> */}
                                        <div className="VideoList_videoplay_box">
                                            <img src={videoPlay} className="VideoList_videoplay" alt="" />
                                        </div>
                                    </div>
                                    <span>{item.title}</span>
                                </div>)
                            })
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default VideoList;
