import "./index.css";

export default function ImgInfo(props: any) {
    let { imgList, imgAbout } = props;
    const imgListArr = (imgList && imgList.length > 0 ? imgList : []).slice(0, 1)
    const imgListNewArr = (imgList && imgList.length > 0 ? imgList : []).slice(1, 5)
    const renderHtml = (item, index) => {
        return (
            <div className="w-full" style={{
                height: '300px',
                position: 'relative',

            }}>
                <img className="w-full h-full" src={item.pic} alt="" />
                <div className="w-full flex items-center justify-center absolute unactived activeHover" style={{
                    height: '300px',
                    top: 0,
                    right: 0,
                    left: 0,
                    color: 'white',
                    backgroundColor: 'rgba(0,0,0,0.4)'
                }}>
                    <span>{imgAbout}</span>
                </div>
            </div>
        )
    }

    const ImgClick = (item) => {
        console.log(item, 'item')
        props.ImgClick(item);
    }
    return (
        <div className="footerSixImg">
            {(imgListArr && imgListArr.length > 0 ? imgListArr : []).map((item, index) => {
                return (
                    <div style={{
                        width: '50%',
                        height: '600px',
                        position: 'relative',
                    }}
                        key={index}
                        onClick={() => {
                            ImgClick(item)
                        }}
                    >
                        <img className="w-full h-full" src={item.pic} alt="" />
                        <div className="w-full h-full flex items-center justify-center absolute unactived activeHover" style={{
                            top: 0,
                            right: 0,
                            left: 0,
                            color: 'white',
                            backgroundColor: 'rgba(0,0,0,0.4)'
                        }}>
                            <span>{imgAbout}</span>
                        </div>
                    </div>
                )
            })}

            <div className="flex flex-wrap" style={{
                width: '50%',
                height: '600px',
            }}>
                {(imgListNewArr && imgListNewArr.length > 0 ? imgListNewArr : []).map((item, index) => {
                    return (<div
                        style={{
                            width: '50%',
                            height: '300px',
                            position: 'relative',
                        }}
                        onClick={() => {
                            ImgClick(item)
                        }}
                    >
                        {
                            renderHtml(item, index)
                        }
                    </div>)
                })}
            </div>

        </div>
    );
}
