import React from "react";
import axios from 'axios'
import { DatePicker, Select, Carousel, Modal } from "antd";
import "./index.css";

import button_left from "../../assets/images/button_left@2x.png";

import button_right from "../../assets/images/button_right@2x.png";

class FoodDetails extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            bannerList: [],
            content: '',
            title: '',
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.initData()
    }

    initData() {
        axios({
            method: "post",
            url: "/Index/index/articleView",
            data: {
                id: this.props.match.params.id || 75
            },
        }).then((res) => {
            this.setState({
                bannerList: [res.data.data.banner],
                title: res.data.data.title,
                content: res.data.data.content,
            })
        });
    }

    // 立即预订
    nextItemSubmit(item: any) {
    }

    nextItemDetails(item: any) {
    }


    render() {
        let { bannerList, content, title } = this.state
        return (
            <div className="FoodDetails">
                {/*  */}
                <div
                    id="carouselExampleInterval"
                    className="carousel slide h-120"
                    data-bs-ride="carousel"
                >
                    <div className="carousel-inner h-120">
                        {(bannerList && bannerList.length > 0 ? bannerList : []).map(
                            (item, index) => (
                                <div
                                    className={`carousel-item ${index === 0 ? "active" : null}`}
                                    key={index}
                                    data-bs-interval="3000"
                                >
                                    <div className="group relative" style={{ height: "100%" }}>
                                        <div
                                            style={{ height: "100%" }}
                                            className="relative w-full h-400 bg-white overflow-hidden group-hover:opacity-75 sm:aspect-w-2 sm:aspect-h-1 sm:h-64 lg:aspect-w-1 lg:aspect-h-1"
                                        >
                                            <img
                                                src={item}
                                                alt=""
                                                style={{ height: "500px" }}
                                                className="w-full object-center object-cover"
                                            />
                                        </div>
                                    </div>
                                </div>
                            )
                        )}
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>

                {/*  */}
                <div>
                    <div className="space-y-4" style={{ borderBottom: '1px solid #DFDFDF' }}>
                        <div
                            className="flow-root px-4 py-5 sm:px-6"
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                            }}
                        >
                            <h3
                                className="text-lg leading-6 font-medium text-gray-900"
                                style={{ color: "#897143", fontSize: '26px' }}
                            >
                                {title}
                            </h3>
                            <br />
                            <div className="mt-1 text-sm text-gray-500" style={{ width: '80%', 'overflow': 'auto' }}>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: content,
                                    }}
                                ></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default FoodDetails;
