import React from "react";
import axios from 'axios'
import "./index.less";

// import Header from "../../components/Header";
// import Footer from "../../components/Footer";
import logobg from "../../assets/images/unsplash_DGa0LQ0yDPc@2x.png";

class Forgetpassword extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
  }

  signUp() {
    axios({
      method: "post",
      url: "/Index/login/resetPassword",
      data: {
        phone: (this.refs.iphone as any).value,
        phone_code: (this.refs.phone_code as any).value,
        password: (this.refs.password as any).value,
      },
    }).then((res) => {
      if(res.data.code == '200'){
        window.localStorage.setItem('loginStatus','true')
        window.localStorage.setItem('loginInfoToken',res.data.data.token)
        this.props.history.push('/Login')
      }else{
      }
    });
  }

  getPhoneCode(){
    axios({
      method: "post",
      url: "/Index/txsms/smsCode",
      data: {
        phone: (this.refs.iphone as any).value,
      },
    }).then((res) => {
      if(res.data.code == '200'){
        console.log(res.data.data.code)
      }else{
      }
    });
  }

  render() {
    return (
      <div className="Home">
        <div
          style={{
            backgroundImage: `url(${logobg})`,
            backgroundSize: "100% 100%",
            height: "500px",
            alignItems: "center",
            backgroundAttachment: "local",
          }}
        >
          <div
            style={{ background: "rgba(0,0,0,.4)" }}
            className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 group-hover:opacity-75"
          >
            <div className="space-y-6 w-64">
              <div>
                <h2 className="mt-6 text-center text-2xl font-extrabold text-gray-100">
                  修改密码
                </h2>
              </div>
              <div>
                <label htmlFor="iphone" className="sr-only">
                  iphone
                </label>
                <input
                  id="iphone"
                  name="iphone"
                  type="text"
                  autoComplete="off"
                  ref="iphone"
                  style={{
                    backgroundColor: "transparent",
                    border: "1px solid #FFFFFF",
                  }}
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-300 text-gray-50 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="请输入手机号"
                />
              </div>
              <div className="flex flex-row justify-center border border-gray-300">
                <label htmlFor="phone_code" className="sr-only">
                phone_code
                </label>
                <input
                  id="phone_code"
                  name="phone_code"
                  type="text"
                  ref="phone_code"
                  style={{
                    backgroundColor: "transparent",
                  }}
                  autoComplete="off"
                  className="appearance-none rounded-none relative block w-full px-3 py-2  placeholder-gray-300 text-gray-50 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="请输入验证码"
                />
                <span onClick={this.getPhoneCode.bind(this)} className="w-36 text-center cursor-pointer" style={{color:'#897143',fontSize:'12px',lineHeight:'38px'}}>获取验证码</span>
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  ref="password"
                  autoComplete="off"
                  style={{
                    backgroundColor: "transparent",
                    border: "1px solid #FFFFFF",
                  }}
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-300 text-gray-50 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="请输入密码"
                />
              </div>
              <div>
                <label htmlFor="againPassword" className="sr-only">
                againPassword
                </label>
                <input
                  id="againPassword"
                  name="againPassword"
                  type="password"
                  ref="againPassword"
                  autoComplete="off"
                  style={{
                    backgroundColor: "transparent",
                    border: "1px solid #FFFFFF",
                  }}
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-300 text-gray-50 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="请再次输入密码"
                />
              </div>
              <div>
                <button
                  type="submit"
                  style={{
                    backgroundColor: "#897143",
                    color: "white",
                    border: "none",
                    outline: "none",
                    fontSize: "12px",
                  }}
                  onClick={this.signUp.bind(this)}
                  className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  注册完成
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* <Footer /> */}
      </div>
    );
  }
}

export default Forgetpassword;
