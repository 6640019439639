import React from "react";
import axios from "axios";
import moment from "moment";
import { DatePicker, Select, Carousel, Modal } from "antd";
import "./index.css";

import Contact from "../../components/Contact";

import ImgInfo from "../../components/ImgInfo";



import CardItem from "../../components/CardItem";

import address from "../../assets/images/icon_address@2x.png";
import iphone from "../../assets/images/icon_l_phone@2x.png";

import icon_food from "../../assets/images/icon_food@2x.png";

import button_left from "../../assets/images/button_left@2x.png";

import button_right from "../../assets/images/button_right@2x.png";
import video from "../../assets/images/unsplash_X5UrOwSCYYI@2x.png";
import group from "../../assets/images/Group473@2x.png";
import down from "../../assets/images/down@2x.png";
import videoPlay from "../../assets/images/icon_play@2x.png";
const { RangePicker } = DatePicker;
const { info } = Modal;

class Home extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      homeConfig: {},
      cxProInfo: {},

      start_time: "",
      end_time: "",
      houseType: "",
      productList: [],
      productSelect: [],
      bannerList: [],
      articleList: [],
      carouselCard: null
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.initData();
  }

  initData() {
    //
    axios({
      method: "post",
      url: "/Index/index/index",
      data: {},
    }).then((res) => {
      console.log(res);
      var articleList = [];
      let articleArr = res.data.data.articleList;
      for (var i = 0; i < articleArr.length; i += 5) {
        let result = articleArr.slice(i, i + 5);
        // @ts-ignore
        articleList.push(result);
      }
      console.log(articleList, "articleList");
      this.setState({
        homeConfig: res.data.data,
        cxProInfo: res.data.data.cxProInfo,
        articleList: articleList,
      });
    });

    // 房源分类
    axios({
      method: "post",
      url: "/Index/index/getProductCate",
      data: {},
    }).then((res) => {
      this.setState({
        productList: res.data.data.listData || [],
        productSelect:
          res.data.data.listData.map((item, index) => ({
            label: item.name,
            value: item.id,
          })) || [],
      });
    });
    // banner
    axios({
      method: "post",
      url: "/Index/index/getBanner",
      data: {},
    }).then((res) => {
      this.setState({
        bannerList: res.data.data.bannerList || [],
      });
    });
  }

  // 立即预订
  nextItemSubmit(item: any) {
    this.props.history.push(`/ScheduledSubmission/${item.product_id}`);
  }
  // 跳转详情
  nextItemDetails(item: any) {
    this.props.history.push(`/ListingDetails/${item.product_id}`);
  }

  imgDeatil(val) {
    if (val === 0) {
      return "col-start-1 col-span-4";
    } else if (val === 1) {
      return "col-start-5 col-span-2";
    } else if (val === 2) {
      return "col-start-7 col-span-2";
    } else if (val === 3) {
      return "col-start-1 col-span-4";
    } else if (val === 4) {
      return "col-start-5 col-span-2";
    } else if (val === 5) {
      return "col-start-7 col-span-2";
    }
  }
  showModal = () => {
    info({
      closable: true,
      width: 800,
      icon: null,
      okText: '确定',
      content: (<div>
        <video
          src={this.state.homeConfig.video_url}
          className="w-full"
          style={{
            height: "400px",
          }}
          controls
          autoPlay
        ></video>
      </div>),
    });
  };

  getImgPic(index) {
    setTimeout(() => {
      console.log(this.state.homeConfig.imgList[index].pic, 'this.state.homeConfig.imgList[index].pic')
      return this.state.homeConfig.imgList[index].pic
    }, 1000)
  }

  render() {
    let { homeConfig, cxProInfo, productSelect, bannerList, articleList } =
      this.state;

    return (
      <div className="Home relative">
        <div
          id="carouselExampleInterval"
          className="carousel slide h-120"
          data-bs-ride="carousel"
        >
          <div className="carousel-inner h-120">
            {(bannerList && bannerList.length > 0 ? bannerList : []).map(
              (item, index) => (
                <div
                  className={`carousel-item ${index === 0 ? "active" : null}`}
                  key={index}
                  data-bs-interval="3000"
                >
                  <div className="group relative" style={{ height: "100%" }}>
                    <div
                      style={{ height: "100%" }}
                      className="relative w-full bg-white overflow-hidden group-hover:opacity-75 sm:aspect-w-2 sm:aspect-h-1 sm:h-64 lg:aspect-w-1 lg:aspect-h-1"
                    >
                      <img
                        src={item.pic}
                        alt=""
                        className="w-full object-center object-cover"
                      />
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
        <div style={{ margin: 'auto', width: '80%' }} className="absolute top-20 right-0 left-0 flex  justify-between py-40">
          <div className="flex flex-row justify-between align-middle w-full">
            <div className="flex flex-col " style={{ width: '30%' }}>
              <label htmlFor="" className="text-gray-50 mb-2">
                入住-离店
              </label>
              <div style={{ background: "transparent" }}>
                <RangePicker
                  style={{
                    background: "transparent",
                    borderRadius: "0",
                    color: "white",
                    height: "40px",
                  }}
                  className="w-full"
                  defaultValue={[
                    moment(new Date(), "YYYY/MM/DD"),
                    moment(new Date(), "YYYY/MM/DD"),
                  ]}
                  format={"YYYY/MM/DD"}
                  suffixIcon={null}
                  allowClear={false}
                  onChange={(dates, dateStrings) => {
                    console.log(dateStrings, "1212");
                    this.setState({
                      start_time: dateStrings[0],
                      end_time: dateStrings[1],
                    });
                  }}
                ></RangePicker>


              </div>
            </div>
            <div className="flex flex-col " style={{ width: '30%' }}>
              <label htmlFor="" className="text-gray-50 mb-2">
                宾客和客房
              </label>
              <select
                className="form-select text-gray-50"
                aria-label="Default select example"
                style={{ background: "transparent", borderRadius: "0", height: '40px' }}
                onChange={(e) => {
                  this.setState({
                    houseType: e.target.value,
                  });
                }}
              >
                {productSelect.map((item, index) => (
                  <option key={index} value={item.value}>{item.label}</option>
                ))}
              </select>
            </div>
            <div className="flex flex-col align-center justify-end" style={{ width: '28%' }}>
              <a
                href="/#/HousingResourcesList/-1"
                style={{ height: '40px' }}
                className="text-gray-50 group relative w-full flex justify-center  py-2 px-2 border border-gray-300 border-transparent text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                查阅房价
              </a>
            </div>
          </div>
        </div>

        {/* <Address homeConfig={homeConfig} /> */}
        <div>
          {/*  */}
          <div className="space-y-4 pb-10" style={{ backgroundColor: '#FBFBFC' }}>
            <div
              className="flow-root px-4 py-8 sm:px-6"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <h3
                className="text-lg leading-6 font-medium text-gray-900"
                style={{ color: "#897143", fontSize: '30px' }}
              >
                上饶国际大酒店
              </h3>
              <br />
              <p
                className="flex align-center justify-between mt-1 max-w-2xl text-sm text-gray-500"
                style={{ color: "#666666" }}
              >
                <span className="flex align-center justify-between mr-10">
                  <img
                    style={{ width: "16px", height: "16px" }}
                    src={address}
                    alt=""
                  />
                  <span style={{ fontSize: '14px' }}>{homeConfig.address} </span>
                </span>
                <span className="flex align-center justify-between">
                  <img
                    style={{ width: "16px", height: "16px" }}
                    src={iphone}
                    alt=""
                  />
                  <span style={{ fontSize: '14px' }}>{homeConfig.service_phone}</span>
                </span>
              </p>
              <br />
              <div className="mt-1 text-sm text-gray-500" style={{ width: '80%' }}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: homeConfig.index_about,
                  }}
                ></div>
              </div>
            </div>
            <div className="flow-root py-10">
              <div className="w-full px-40">
                <CardItem specList={homeConfig.specList}></CardItem>
              </div>
            </div>
          </div>

          {/*  */}
          <div
            id="carouselExampleSlidesOnly"
            className="carousel slide h-120"
            data-bs-ride="carousel"
          >
            <div className="carousel-inner h-120">
              {(homeConfig.hotProList && homeConfig.hotProList.length > 0
                ? homeConfig.hotProList
                : []
              ).map((item, index) => (
                <div
                  className={`carousel-item ${index === 0 ? "active" : null}`}
                  key={index}
                >
                  <div className="grid grid-cols-8 gap-4">
                    <div className="col-start-1 col-span-6 h-100" onClick={this.nextItemDetails.bind(this, item)}>
                      <div
                        className="group relative"
                        style={{ height: "100%" }}
                      >
                        <div
                          style={{ height: "100%" }}
                          className="relative w-full h-400 bg-white overflow-hidden group-hover:opacity-75 sm:aspect-w-2 sm:aspect-h-1 sm:h-64 lg:aspect-w-1 lg:aspect-h-1"
                        >
                          <img
                            src={item.product_pic}
                            alt=""
                            style={{ height: "580px" }}
                            className="w-full object-center object-cover"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-start-7 col-end-9 h-full">
                      <div className="space-y-4 h-full flex flex-col justify-around" style={{ backgroundColor: '#FFFFFF' }}>
                        <div
                          className="flow-root px-4 py-2 sm:px-6"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                          onClick={this.nextItemDetails.bind(this, item)}
                        >
                          <h3
                            className="text-lg leading-6 font-medium text-gray-900"
                            style={{ color: "#453A2C", fontSize: '24px' }}
                          >
                            {item.product_name}
                          </h3>
                          <div className="mt-1 max-w-2xl text-sm text-gray-500" style={{ color: '#666666', 'lineHeight': '24px' }}>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: item.product_info,
                              }}
                            ></div>
                          </div>
                        </div>
                        <div className="flow-root">
                          <div className="w-full">
                            <div className="">
                              <div className="flex flex-row items-center justify-between flex-wrap"
                                style={{
                                  borderBottom: "1px solid #f0f0f0",
                                }}
                              >
                                {item.specs.map((specItem, specIndex) => (
                                  <div
                                    key={specIndex}
                                    className="mt-2"
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                      padding: "10px 10px",
                                      borderRight: "1px solid #f0f0f0",
                                      width: "33%",
                                      height: '90px',
                                    }}
                                  >
                                    <img
                                      style={{ width: "30px", height: "30px" }}
                                      src={specItem.spec_pic}
                                      alt=""
                                    />
                                    <span
                                      style={{
                                        color: "#333333",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {specItem.spec_name}
                                    </span>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-row my-2 items-center" style={{ justifyContent: 'center' }} >
                          <img
                            className="w-8 h-8 mx-3"
                            src={button_left}
                            alt=""
                            data-bs-target="#carouselExampleSlidesOnly"
                            data-bs-slide="prev"
                          />
                          <span>
                            {index + 1}/{homeConfig.hotProList.length}
                          </span>
                          <img
                            className="w-8 h-8 mx-3"
                            src={button_right}
                            alt=""
                            data-bs-target="#carouselExampleSlidesOnly"
                            data-bs-slide="next"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/*  */}
          <div className="" style={{ backgroundColor: '#FFFFFF' }}>
            <div className="grid grid-cols-8 gap-4">
              <div className="col-start-1 col-end-3">
                <div className="space-y-4 h-full flex flex-col justify-between">
                  <div
                    className="flow-root px-4 py-5 sm:px-6"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                    onClick={this.nextItemDetails.bind(this, cxProInfo)}
                  >
                    <h3
                      className="leading-6 font-medium text-gray-900"
                      style={{ color: "#453A2C", fontSize: '24px' }}
                    >
                      {cxProInfo.product_name}
                    </h3>
                    <div className="mt-4 max-w-2xl text-gray-500" style={{ color: '#666666', 'lineHeight': '24px' }}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: cxProInfo.product_info
                        }}
                      ></div>
                    </div>
                  </div>
                  <div className="flow-root py-2 w-full" style={{ marginBottom: '20px' }}>
                    <div className="w-full">
                      <div className="flex flex-row justify-around my-2 items-center">
                        <button
                          style={{
                            backgroundColor: "#897143",
                            border: "1px solid #897143",
                            color: "white",
                            outline: "none",
                            padding: "8px 35px",
                            fontSize: "13px",
                          }}
                          onClick={this.nextItemSubmit.bind(this, cxProInfo)}
                        >
                          立即预订
                        </button>
                        <button
                          style={{
                            border: "1px solid #333333",
                            color: "#333333",
                            outline: "none",
                            padding: "8px 35px",
                            fontSize: "13px",
                          }}
                          onClick={this.nextItemDetails.bind(this, cxProInfo)}
                        >
                          查询详情
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-start-3 col-span-9 h-100" onClick={this.nextItemDetails.bind(this, cxProInfo)}>
                <div className="group relative  h-100">
                  <div className="relative w-full h-100  bg-white overflow-hidden group-hover:opacity-75 sm:aspect-w-2 sm:aspect-h-1 sm:h-64 lg:aspect-w-1 lg:aspect-h-1">
                    <img
                      src={cxProInfo.product_pic}
                      alt=""
                      style={{ height: "580px" }}
                      className="w-full object-center object-cover"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*  */}
          <div className=" py-5 " style={{ backgroundColor: '#FBFBFC' }}>
            <div
              className="flex flex-row px-4 py-3 sm:px-6 justify-around"
              style={{
                alignItems: "center",
              }}
            >
              <h3
                className="text-lg leading-6 font-medium text-gray-900"
                style={{ color: "#897143", fontSize: '26px' }}
              >
                品味美食
              </h3>
              <div className="mt-1 max-w-2xl text-sm text-gray-500">
                <div
                  dangerouslySetInnerHTML={{
                    __html: homeConfig.jd_about,
                  }}
                ></div>
              </div>
            </div>
            <div className="">
              <Carousel ref={e => {
                // 走马灯dom名card
                //@ts-ignore
                this.carouselCard = e;
              }}>
                {(articleList && articleList.length > 0 ? articleList : []).map(
                  (item, index) => (
                    <div key={index} className="py-8 px-4 sm:py-10 sm:px-6  lg:px-8">
                      <div className="flex flex-row justify-between" style={{
                        // height: '280px'
                      }}>
                        {item.map((product, productIndex) => (
                          <a
                            key={productIndex}
                            style={{ width: "40%" }}
                            className="group p-2"
                            onClick={() => {
                              this.props.history.push(`/FoodDetails/${product.id}`)
                            }}
                          >
                            <div className="w-full bg-gray-200 overflow-hidden">
                              <img
                                src={product.pic}
                                alt={""}
                                style={{
                                  width: '400px',
                                  height: '300px'
                                }}
                                className="w-full h-full object-center object-cover group-hover:opacity-75"
                              />
                            </div>
                            <h3
                              className="mt-4 text-gray-700"
                              style={{ color: "#453A2C", fontSize: "20px" }}
                            >
                              {product.title}
                            </h3>
                            <p
                              className="mt-1 text-gray-900"
                              style={{ color: "#666666", fontSize: "13px" }}
                            >
                              {product.title_info}
                            </p>
                          </a>
                        ))}
                      </div>
                    </div>
                  )
                )}
              </Carousel>

              {/* articleList */}
              <div className="flex flex-row justify-center my-4 items-center">
                <img
                  className="w-8 h-8 mx-3"
                  src={button_left}
                  alt=""
                  onClick={() => {
                    //  @ts-ignore
                    this.carouselCard.prev()
                  }}
                />
                <img
                  className="w-8 h-8 mx-3"
                  src={button_right}
                  alt=""
                  onClick={() => {
                    //  @ts-ignore
                    this.carouselCard.next()
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        {/* <Content /> */}

        {/* <HotelIntroduce homeConfig={homeConfig} /> */}

        <div className="bg-gray-100">
          <div className="grid grid-cols-8 gap-4">
            <div className="col-start-1 col-span-9">
              <div className="group relative">
                <div className="relative w-full bg-white overflow-hidden group-hover:opacity-75 sm:aspect-w-2 sm:aspect-h-1 sm:h-300 lg:aspect-w-1 lg:aspect-h-1">
                  <img
                    src="https://huayunjd.oss-cn-hangzhou.aliyuncs.com/upload/20211231/788b2bdf16b0.jpeg"
                    alt={""}
                    className="w-full h-full object-center object-cover"
                  />
                  <div className="absolute flex flex-col items-center justify-center top-0 w-full h-full">
                    {/* <p
                      className="py-6"
                      style={{ fontSize: "16px", color: "white" }}
                    >
                      大标题大标题
                    </p> */}
                    <div
                      className="py-2"
                    // style={{ fontSize: "12px", color: "white" }}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: homeConfig.gg_about,
                        }}
                      ></div>
                    </div>
                    <div className="flex flex-col align-center justify-end w-60 px-8 py-10 mt-6">
                      <a
                        href="/#/HotelIntroduction"
                        className="text-gray-50 group relative w-full flex justify-center  py-2 px-2 border border-gray-300 border-transparent text-sm  text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        查询详情
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <HotelInfo homeConfig={homeConfig} /> */}
        <div className="bg-white">
          <div className="grid grid-cols-8 gap-4" style={{ height: "400px" }}>
            <div className="col-start-1 col-span-5" style={{ height: "400px" }}>
              <div
                style={{ height: "100%" }}
                className="relative w-full h-full bg-white overflow-hidden group-hover:opacity-75 sm:aspect-w-2 sm:aspect-h-1 sm:h-64 lg:aspect-w-1 lg:aspect-h-1"
              >
                <img
                  src={homeConfig.video_img}
                  alt={''}
                  style={{ height: "100%" }}
                  className="w-full h-full object-center object-cover"
                />
                <div className="absolute right-0 left-0 top-0 flex  h-full items-center justify-center">
                  <img className="w-16 h-16" src={videoPlay} onClick={this.showModal} alt="" />
                </div>
                {/*<video*/}
                {/*  src={homeConfig.video_url}*/}
                {/*  style={{ height: "100%" }}*/}
                {/*  className="w-full h-full object-center object-cover"*/}
                {/*  controls*/}
                {/*></video>*/}
              </div>
            </div>

            <div className="col-start-6 col-end-9 py-10">
              <div className="flex flex-col items-start justify-start relative w-full h-full bg-white overflow-hidden group-hover:opacity-75 sm:aspect-w-2 sm:aspect-h-1 sm:h-64 lg:aspect-w-1 lg:aspect-h-1">
                <h3
                  className="text-lg leading-6 font-medium text-gray-900"
                  style={{ color: "#897143", fontSize: '26px' }}
                >
                  康体中心 Fitness center
                </h3>
                <br />
                <p
                  className="flex align-center justify-between mt-1 max-w-2xl text-sm text-gray-500"
                  style={{ color: "#666666" }}
                ></p>
                <br />
                <div className="mt-1 max-w-2xl text-sm text-gray-500">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: homeConfig.video_about,
                    }}
                  ></div>
                  {/* 上饶国际大酒店位于上饶中心区广信大道东侧、星河国际南侧，陆羽公园北侧。酒店建筑总面积为56088平方米，约500个停车位楼高99.65米共24层，总投资约5亿元，并通过国家星评委专家按照五星级酒店标准先进行预审，硬件设施齐全。 */}
                </div>
                <button
                  style={{
                    border: "1px solid #333333",
                    color: "#333333",
                    outline: "none",
                    padding: "8px 35px",
                    fontSize: "13px",
                  }}
                  className="mt-10"
                  onClick={() => {
                    this.props.history.push("/ContactUs");
                  }}
                >
                  查询详情
                </button>
              </div>
            </div>
          </div>
        </div>

        <Contact homeConfig={homeConfig} />

        <ImgInfo imgList={homeConfig.imgList} imgAbout={homeConfig.img_about} ></ImgInfo>
      </div>
    );
  }
}

export default Home;
