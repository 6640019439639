import React from "react";
import axios from "axios";
import moment from "moment";
import { DatePicker, Select } from "antd";
import "./index.css";

import "tailwindcss/tailwind.css";

// import Header from "../../components/Header";
// import Footer from "../../components/Footer";
import Contact from "../../components/Contact";
const { Option } = Select;
const { RangePicker } = DatePicker;
class HousingResourcesList extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      sysConfig: {},
      houseResourcesList: [],

      start_time: "",
      end_time: "",
      houseType: null,
      productList: [],
      productSelect: [],
    };
  }
  componentDidMount() {
    this.setState({
      houseType: (this.props.match.params.id == -1) ? '' : this.props.match.params.id
    })
    window.scrollTo(0, 0);
    this.initData();
  }

  getProduct() {
    // 房源列表
    axios({
      method: "post",
      url: "/Index/index/getProduct",
      data: {
        cid: this.state.houseType,
        start_time: this.state.start_time,
        end_time: this.state.end_time,
      },
    }).then((res) => {
      console.log(res);
      this.setState({
        houseResourcesList: res.data.data.List,
      });
    });
  }

  initData() {

    // 房源列表
    axios({
      method: "post",
      url: "/Index/index/getProduct",
      data: {
        cid: this.state.houseType,
        start_time: this.state.start_time,
        end_time: this.state.end_time,
      },
    }).then((res) => {
      console.log(res);
      this.setState({
        houseResourcesList: res.data.data.List,
      });
    });

    // 房源分类
    axios({
      method: "post",
      url: "/Index/index/getProductCate",
      data: {},
    }).then((res) => {
      this.setState({
        productList: res.data.data.listData || [],
        productSelect:
          res.data.data.listData.map((item, index) => ({
            label: item.name,
            value: item.id,
          })) || [],
      });
    });
  }


  // 立即预订
  nextItemSubmit(item: any) {
    this.props.history.push(`/ScheduledSubmission/${item.product_id}`);
  }

  nextItemDetails(item: any) {
    this.props.history.push(`/ListingDetails/${item.product_id}`);
  }


  render() {
    let { productSelect, houseType } = this.state;
    return (
      <div className="HousingResourcesList">
        <div className="HousingResourcesList_content">
          <div className="flex flex-row justify-between align-middle px-32 py-8">
            <div className="flex flex-col" style={{ width: '30%' }}>
              <label htmlFor="" className="text-gray-900 mb-2">
                入住-离店
              </label>
              <div style={{ background: "transparent", border: '1px solid #333333' }}>
                <RangePicker
                  style={{
                    background: "transparent",
                    borderRadius: "0",
                    color: "white",
                    height: "38px",
                  }}
                  className="w-full"
                  defaultValue={[
                    moment(new Date(), "YYYY/MM/DD"),
                    moment(new Date(), "YYYY/MM/DD"),
                  ]}
                  format={"YYYY/MM/DD"}
                  suffixIcon={null}
                  allowClear={false}
                  onChange={(dates, dateStrings) => {
                    console.log(dateStrings, "1212");
                    this.setState({
                      start_time: dateStrings[0],
                      end_time: dateStrings[1],
                    });
                  }}
                ></RangePicker>
              </div>
            </div>
            <div className="flex flex-col " style={{ width: '30%' }}>
              <label htmlFor="" className="text-gray-900 mb-2">
                宾客和客房
              </label>
              <Select
                className="form-select text-gray-900"
                defaultValue={(Number(this.props.match.params.id) == -1 ? '' : Number(this.props.match.params.id))}
                style={{ background: "transparent", borderRadius: "0", border: '1px solid #333333', height: '40px' }}
                onChange={(e) => {
                  this.setState({
                    houseType: e,
                  });
                }}
              >
                {productSelect.map((item) => (
                  <Option value={item.value} >{item.label}</Option>
                ))}
              </Select>
            </div>
            <div className="flex flex-col align-center justify-end " style={{ width: '26%' }}>
              <span
                onClick={() => {
                  console.log(('1212'))
                  this.getProduct()
                }}
                style={{
                  backgroundColor: '#897143',
                }}
                className="text-gray-50 group relative w-full flex justify-center  py-2 px-2 border  border-transparent bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                搜索
              </span>
            </div>
          </div>
          {/*  */}
          {
            this.state.houseResourcesList.length > 0 ? (<div></div>) : (<div className="px-32 h-40" style={{ 'textAlign': 'center' }}>
              <span style={{ color: '#666666', 'marginTop': '20px' }}>
                暂无数据
              </span>
            </div>)
          }
          <div className="px-32">
            {this.state.houseResourcesList.map((item, index) => {
              return (
                <div className="">
                  {(index + 1) % 2 == 0 ? (
                    <div className="grid grid-cols-8 gap-4 my-6">
                      <div className="col-start-1 col-end-4">
                        <div
                          className="space-y-4 h-full flex justify-around flex-col"
                          style={{ alignItems: "center" }}
                        >
                          <div
                            className="w-full flex flex-col"
                            style={{
                              alignItems: "flex-start",
                            }}
                            onClick={this.nextItemDetails.bind(this, item)}
                          >
                            <h3
                              className="text-lg leading-6 font-medium text-gray-900"
                              style={{ color: "#453A2C" }}
                            >
                              {item.product_name}
                            </h3>
                            <div className="mt-1 max-w-2xl text-sm text-gray-500 w-full" style={{ color: '#666666' }}>
                              {/* {item.product_info} */}
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: item.product_info,
                                }}
                              ></div>
                            </div>
                          </div>
                          <div className="flow-root py-2 w-full" >
                            <div className="w-full">
                              <div className=" " onClick={this.nextItemDetails.bind(this, item)}>
                                <div className="flex flex-row items-center justify-between flex-wrap">
                                  {item.specs.map((specItem) => {
                                    return (
                                      <div
                                        className="mt-2"
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          alignItems: "center",
                                          flexWrap: 'wrap',
                                          padding: "10px 10px",
                                          borderRight: "1px solid #f0f0f0",
                                          width: 'calc(33% - 20px)',
                                          height: '100px',
                                        }}
                                      >
                                        <img
                                          style={{
                                            width: "30px",
                                            height: "30px",
                                          }}
                                          src={specItem.spec_pic}
                                          alt=""
                                        />
                                        <span
                                          className="py-2"
                                          style={{
                                            color: "#333333",
                                            fontSize: "11px",
                                          }}
                                        >
                                          {specItem.spec_name}
                                        </span>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                              <div
                                className="flex flex-row py-4 mt-6 justify-between px-4 sm:px-6"
                                style={{
                                  alignItems: "center",
                                  color: "#333333",
                                  fontSize: "16px",
                                }}
                              >
                                <span style={{ fontSize: "14px", }}>    <span style={{'fontSize':'16px',color:'rgb(170, 148, 99)'}}>{item.sales_num} </span>  </span>
                                <button
                                  style={{
                                    backgroundColor: "#897143",
                                    border: "1px solid #897143",
                                    color: "white",
                                    outline: "none",
                                    padding: "8px 35px",
                                    fontSize: "14px",
                                  }}
                                  onClick={this.nextItemSubmit.bind(this, item)}
                                >
                                  立即预订
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-start-4 col-span-8 h-100" onClick={this.nextItemDetails.bind(this, item)}>
                        <div
                          className="group relative"
                          style={{ height: "100%" }}
                        >
                          <div
                            style={{ height: "100%" }}
                            className="relative w-full h-400 bg-white overflow-hidden group-hover:opacity-75 sm:aspect-w-2 sm:aspect-h-1 sm:h-64 lg:aspect-w-1 lg:aspect-h-1"
                          >
                            <img
                              src={item.product_pic}
                              alt={item.product_name}
                              style={{ height: "480px" }}
                              className="w-full h-full object-center object-cover"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="grid grid-cols-8 gap-4 my-6">
                      <div className="col-start-1 col-span-5 h-100" onClick={this.nextItemDetails.bind(this, item)}>
                        <div
                          className="group relative"
                          style={{ height: "100%" }}
                        >
                          <div
                            style={{ height: "100%" }}
                            className="relative w-full h-400 bg-white overflow-hidden group-hover:opacity-75 sm:aspect-w-2 sm:aspect-h-1 sm:h-64 lg:aspect-w-1 lg:aspect-h-1"
                          >
                            <img
                              src={item.product_pic}
                              alt={item.product_name}
                              style={{ height: "480px" }}
                              className="w-full h-full object-center object-cover"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-start-6 col-end-9" >
                        <div
                          className="space-y-4 h-full flex justify-around flex-col"
                          style={{ alignItems: "center" }}
                        >
                          <div
                            className="w-full flex flex-col"
                            style={{
                              alignItems: "flex-start",
                            }}
                          >
                            <h3
                              className="text-lg leading-6 font-medium text-gray-900"
                              style={{ color: "#453A2C" }}
                            >
                              {item.product_name}
                            </h3>
                            <div className="mt-1 max-w-2xl text-sm text-gray-500 w-full" style={{ color: "#666666" }}>
                              {/* {item.product_info} */}
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: item.product_info,
                                }}
                              ></div>
                            </div>
                          </div>
                          <div className="flow-root py-2 w-full" >
                            <div className="w-full">
                              <div className=" " onClick={this.nextItemDetails.bind(this, item)}>
                                <div
                                  className="mt-2"
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    flexWrap: 'wrap',
                                    justifyContent: "space-between",
                                  }}
                                >
                                  {item.specs.map((specItem) => {
                                    return (
                                      <div
                                        className="mt-2 flex flex-col"
                                        style={{
                                          alignItems: "center",
                                          padding: "10px 10px",
                                          borderRight: "1px solid #f0f0f0",
                                          width: 'calc(33% - 20px)',
                                          height: '100px',
                                        }}
                                      >
                                        <img
                                          style={{
                                            width: "30px",
                                            height: "30px",
                                          }}
                                          src={specItem.spec_pic}
                                          alt=""
                                        />
                                        <span
                                          className="py-2"
                                          style={{
                                            color: "#333333",
                                            fontSize: "11px",
                                          }}
                                        >
                                          {specItem.spec_name}
                                        </span>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                              <div
                                className="flex flex-row py-4 mt-6 justify-between px-4 sm:px-6"
                                style={{
                                  alignItems: "center",
                                  color: "#333333",
                                  fontSize: "14px",
                                }}
                              >
                                <span>    <span style={{'fontSize':'16px',color:'rgb(170, 148, 99)'}}>{item.sales_num} </span>  </span>
                                <button
                                  style={{
                                    backgroundColor: "#897143",
                                    border: "1px solid #897143",
                                    color: "white",
                                    outline: "none",
                                    padding: "8px 35px",
                                    fontSize: "14px",
                                  }}
                                  onClick={this.nextItemSubmit.bind(this, item)}
                                >
                                  立即预订
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
        <Contact />
        {/* <Footer config={this.state.sysConfig} /> */}
      </div>
    );
  }
}

export default HousingResourcesList;
