import React from "react";
import "./index.less";

import "tailwindcss/tailwind.css";

// import Header from "../../components/Header";
// import Footer from "../../components/Footer";

import mine from "../../assets/images/icon_mine@2x.png";
import account from "../../assets/images/icon_phone_1@2x.png";
import leftArrow from "../../assets/images/y_arrow@2x.png";

import itemImage from "../../assets/images/unsplash_FqqiAvJejto@2x.png";

let orderList = [{}, {}, {}];

class OrderDetail extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      type: "1",
    };
  }

  render() {
    return (
      <div className="OrderDetail" >
        <div className="flex flex-row my-4 bg-white h-28" style={{ alignItems: "center" }}>
        12
        </div>

        <div className="flex flex-row my-4 bg-white h-28 mt-4" style={{ alignItems: "center" }}>
          23
        </div>
      </div>
    );
  }
}

export default OrderDetail;
