import React from "react";
import axios from "axios";
import moment from "moment";
import { DatePicker, Select, Radio } from "antd";
import "./index.css";
import "tailwindcss/tailwind.css";
import Contact from "../../components/Contact";
const { RangePicker } = DatePicker;

class HotelReservation extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    console.log(props.config);
    this.state = {
      sysConfig: {},
      houseResourcesConfig: {
        banner: '',
        yd_content: ''
      },
      houseResourcesList: [],
      currentResourcesConfig: {
        product: '',
        cate_name: '',
        cate_content: ''
      },

      start_time: "",
      end_time: "",
      houseType: "",
      productList: [],
      productSelect: [],
      bannerList: [],
      allproduct: [],
      activeId: -1,
      houseResourcesAllList: []
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.initData();
  }
  initData() {
    //
    axios({
      method: "post",
      url: "/Index/index/getProductHome",
      data: {
        cid: "",
      },
    }).then((res) => {
      console.log(res);
      this.setState({
        houseResourcesConfig: res.data.data,
        houseResourcesAllList: res.data.data.List,
        houseResourcesList: [...[{
          cate_id: -1,
          cate_name: "全部",
          product: []
        }], ...res.data.data.List],
      });

      // setTimeout(() => {
      //   this.activeItem({
      //     target: {
      //       value: -1
      //     }
      //   })
      // }, 1000)
    });
    // 房源分类
    axios({
      method: "post",
      url: "/Index/index/getProductCate",
      data: {},
    }).then((res) => {
      this.setState({
        productList: res.data.data.listData || [],
        productSelect:
          res.data.data.listData.map((item, index) => ({
            label: item.name,
            value: item.id,
          })) || [],
      });
    });
    // banner
    axios({
      method: "post",
      url: "/Index/index/getBanner",
      data: {},
    }).then((res) => {
      this.setState({
        bannerList: res.data.data.bannerList || [],
      });
    });
  }

  activeItem(e) {
    this.setState({
      activeId: e.target.value
    })
    if (e.target.value == - 1) {
      let currentResourcesConfig = this.state.houseResourcesList.map((item) => (item.product)).flat()
      this.setState({
        currentResourcesConfig: {
          product: currentResourcesConfig
        }
      })
    } else {
      this.setState({
        currentResourcesConfig: this.state.houseResourcesList.find(
          (item) => item.cate_id == e.target.value
        ),
      });
    }

  }
  // 立即预订
  nextItemSubmit(item: any) {
    this.props.history.push(`/ScheduledSubmission/${item.product_id}`);
  }

  // 列表页面
  nextlistSubmit(item: any) {
    this.props.history.push(`/HousingResourcesList/${this.state.activeId}`);
  }

  nextItemDetails(item: any) {
    this.props.history.push(`/ListingDetails/${item.product_id}`);
  }

  render() {
    let { productSelect, bannerList, houseResourcesAllList } = this.state;
    let { banner, yd_content, houseResourcesList } = this.state.houseResourcesConfig;
    let { product, cate_name, cate_content } = this.state.currentResourcesConfig;

    return (
      <div className="HotelReservation">
        {/* <Header config={this.state.sysConfig} /> */}
        <div className="HotelReservation_content relative">
          {/*  */}
          <div
            id="carouselExampleInterval"
            className="carousel slide h-120 "
            data-bs-ride="carousel"
          >
            <div className="carousel-inner h-120">
              {(bannerList && bannerList.length > 0 ? bannerList : []).map(
                (item, index) => (
                  <div
                    className={`carousel-item ${index === 0 ? "active" : null}`}
                    key={index}
                    data-bs-interval="3000"
                  >
                    <div className="group relative" style={{ height: "100%" }}>
                      <div
                        style={{ height: "100%" }}
                        className="relative w-full h-400 bg-white overflow-hidden group-hover:opacity-75 sm:aspect-w-2 sm:aspect-h-1 sm:h-64 lg:aspect-w-1 lg:aspect-h-1"
                      >
                        <img
                          src={item.pic}
                          alt=""
                          style={{ height: "441px" }}
                          className="w-full object-center object-cover"
                        />
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
          <div style={{ margin: 'auto', width: '80%' }} className="absolute top-20 right-0 left-0 flex  justify-between py-40">
            <div className="flex flex-row justify-between align-middle w-full">
              <div className="flex flex-col " style={{ width: '30%' }}>
                <label htmlFor="" className="text-gray-50 mb-2">
                  入住-离店
                </label>
                <div style={{ background: "transparent" }}>
                  <RangePicker
                    style={{
                      background: "transparent",
                      borderRadius: "0",
                      color: "white",
                      height: "40px",
                    }}
                    className="w-full"
                    defaultValue={[
                      moment(new Date(), "YYYY/MM/DD"),
                      moment(new Date(), "YYYY/MM/DD"),
                    ]}
                    format={"YYYY/MM/DD"}
                    suffixIcon={null}
                    allowClear={false}
                    onChange={(dates, dateStrings) => {
                      console.log(dateStrings, "1212");
                      this.setState({
                        start_time: dateStrings[0],
                        end_time: dateStrings[1],
                      });
                    }}
                  ></RangePicker>
                </div>
              </div>
              <div className="flex flex-col " style={{ width: '30%' }}>
                <label htmlFor="" className="text-gray-50 mb-2">
                  宾客和客房
                </label>
                <select
                  className="form-select text-gray-50"
                  aria-label="Default select example"
                  style={{ background: "transparent", borderRadius: "0", height: '40px' }}
                  onChange={(e) => {
                    this.setState({
                      houseType: e.target.value,
                    });
                  }}
                >
                  {productSelect.map((item, index) => (
                    <option key={index} value={item.value}>{item.label}</option>
                  ))}
                </select>
              </div>
              <div className="flex flex-col align-center justify-end" style={{ width: '28%' }}>
                <a
                  href="/#/HousingResourcesList/-1"
                  style={{ height: '40px' }}
                  className="text-gray-50 group relative w-full flex justify-center  py-2 px-2 border border-gray-300 border-transparent text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  查阅房价
                </a>
              </div>
            </div>
          </div>
          {/*  */}
          <div className="px-28">
            <p className="py-12" style={{ color: "#48433D", fontSize: "16px" }}>
              {yd_content}
            </p>
            <div className="flex flex-col justify-center">
              <div className="flex flex-row justify-center">
                <Radio.Group onChange={(e) => {
                  this.activeItem(e)
                }} defaultValue={-1} size="large" buttonStyle="solid">
                  {this.state.houseResourcesList.map((item, index) => {
                    return (
                      <Radio.Button key={index} value={item.cate_id}> {item.cate_name}</Radio.Button>
                    );
                  })}
                </Radio.Group>
              </div>

              {
                this.state.activeId == -1 ? (<div>
                  {
                    (houseResourcesAllList && houseResourcesAllList.length > 0 ? houseResourcesAllList : []).map((resource, resourceIndex,) => (
                      <div className="" key={resourceIndex}>
                        <p
                          style={{
                            color: "#897143",
                            fontSize: "24px",
                            fontWeight: "600",
                            marginTop: "20px",
                          }}
                          className="py-5 text-center"
                        >
                          {resource.cate_name}
                        </p>
                        <div style={{ color: "#666666" }} className="py-5 px-12">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: resource.cate_content,
                            }}
                          ></div>
                        </div>
                        {/*  */}
                        <div className="">
                          {(resource.product && resource.product.length > 0 ? resource.product : []).map(
                            (item, index) => {
                              return (
                                <div className="" key={index}>
                                  {(index + 1) % 2 == 0 ? (
                                    <div className="grid grid-cols-8 gap-4 my-6">
                                      <div className="col-start-1 col-end-4" >
                                        <div className="space-y-4">
                                          <div className="flex flex-col py-5 " onClick={this.nextItemDetails.bind(this, item)}>
                                            <h3
                                              className="text-lg leading-6 font-medium text-gray-900"
                                              style={{ color: "#453A2C" }}
                                            >
                                              {item.product_name}
                                            </h3>
                                            <div className="mt-1 max-w-2xl text-sm text-gray-500" style={{ color: '#666666', 'lineHeight': '24px' }}>
                                              <div
                                                dangerouslySetInnerHTML={{
                                                  __html: item.product_info,
                                                }}
                                              ></div>
                                            </div>
                                          </div>
                                          <div className="flow-root py-2" onClick={this.nextItemDetails.bind(this, item)}>
                                            <div className="w-full">
                                              <div className=" ">
                                                <div
                                                  className="table-row"
                                                  style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    flexWrap: 'wrap',
                                                    alignItems: "center",
                                                    justifyContent: "space-between",
                                                  }}
                                                >
                                                  {item.specs.map((specItem, specIndex) => {
                                                    return (
                                                      <div
                                                        key={specIndex}
                                                        className="table-cell  mt-2"
                                                        style={{
                                                          display: "flex",
                                                          flexDirection: "column",
                                                          alignItems: "center",
                                                          padding: "10px 10px",
                                                          borderRight:
                                                            "1px solid #f0f0f0",
                                                          width: "33%",
                                                          height: "90px"
                                                        }}
                                                      >
                                                        <img
                                                          style={{
                                                            width: "30px",
                                                            height: "30px",
                                                          }}
                                                          src={specItem.spec_pic}
                                                          alt=""
                                                        />
                                                        <span
                                                          style={{
                                                            color: "#333333",
                                                            fontSize: "11px",
                                                          }}
                                                        >
                                                          {specItem.spec_name}
                                                        </span>
                                                      </div>
                                                    );
                                                  })}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="flex flex-row my-2 justify-between"
                                            style={{
                                              alignItems: "center",
                                              color: "#333333",
                                              fontSize: "14px",
                                            }}
                                          >
                                            <span>    <span style={{ 'fontSize': '16px', color: 'rgb(170, 148, 99)' }}>{item.sales_num} </span> </span>
                                            <button
                                              style={{
                                                backgroundColor: "#897143",
                                                border: "1px solid #897143",
                                                color: "white",
                                                outline: "none",
                                                padding: "8px 35px",
                                                fontSize: "14px",
                                              }}
                                              onClick={this.nextItemSubmit.bind(
                                                this,
                                                item
                                              )}
                                            >
                                              立即预订
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-start-4 col-span-8 h-100" onClick={this.nextItemDetails.bind(this, item)}>
                                        <div
                                          className="group relative"
                                          style={{ height: "100%" }}
                                        >
                                          <div
                                            style={{ height: "100%" }}
                                            className="relative w-full h-400 bg-white overflow-hidden group-hover:opacity-75 sm:aspect-w-2 sm:aspect-h-1 sm:h-64 lg:aspect-w-1 lg:aspect-h-1"
                                          >
                                            <img
                                              src={item.product_pic}
                                              alt={item.product_name}
                                              style={{ height: "480px" }}
                                              className="w-full h-full object-center object-cover"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="grid grid-cols-8 gap-4 my-6">
                                      <div className="col-start-1 col-span-5 h-100" onClick={this.nextItemDetails.bind(this, item)}>
                                        <div
                                          className="group relative"
                                          style={{ height: "100%" }}
                                        >
                                          <div
                                            style={{ height: "100%" }}
                                            className="relative w-full h-400 bg-white overflow-hidden group-hover:opacity-75 sm:aspect-w-2 sm:aspect-h-1 sm:h-64 lg:aspect-w-1 lg:aspect-h-1"
                                          >
                                            <img
                                              src={item.product_pic}
                                              alt={item.product_name}
                                              style={{ height: "480px" }}
                                              className="w-full h-full object-center object-cover"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-start-6 col-end-9" >
                                        <div className="space-y-4">
                                          <div className="flex flex-col py-5 " onClick={this.nextItemDetails.bind(this, item)}>
                                            <h3
                                              className="text-lg leading-6 font-medium text-gray-900"
                                              style={{ color: "#453A2C" }}
                                            >
                                              {item.product_name}
                                            </h3>
                                            <div className="mt-1 max-w-2xl text-sm text-gray-500" style={{ color: "#666666", 'lineHeight': '24px' }}>
                                              {/* {item.product_info} */}
                                              <div
                                                dangerouslySetInnerHTML={{
                                                  __html: item.product_info,
                                                }}
                                              ></div>
                                            </div>
                                          </div>
                                          <div className="flow-root py-2" onClick={this.nextItemDetails.bind(this, item)}>
                                            <div className="w-full">
                                              <div className=" ">
                                                <div
                                                  className="table-row"
                                                  style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    flexWrap: 'wrap',
                                                    alignItems: "center",
                                                    justifyContent: "space-between",
                                                  }}
                                                >
                                                  {item.specs.map((specItem, specIndex) => {
                                                    return (
                                                      <div
                                                        key={specIndex}
                                                        className="table-cell mt-2"
                                                        style={{
                                                          display: "flex",
                                                          flexDirection: "column",
                                                          alignItems: "center",
                                                          padding: "10px 10px",
                                                          borderRight:
                                                            "1px solid #f0f0f0",
                                                          width: "33%",
                                                          height: "90px"
                                                        }}
                                                      >
                                                        <img
                                                          style={{
                                                            width: "30px",
                                                            height: "30px",
                                                          }}
                                                          src={specItem.spec_pic}
                                                          alt=""
                                                        />
                                                        <span
                                                          style={{
                                                            color: "#333333",
                                                            fontSize: "11px",
                                                          }}
                                                        >
                                                          {specItem.spec_name}
                                                        </span>
                                                      </div>
                                                    );
                                                  })}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="flex flex-row my-2 justify-between"
                                            style={{
                                              alignItems: "center",
                                              color: "#333333",
                                              fontSize: "14px",
                                            }}
                                          >
                                            <span>    <span style={{ 'fontSize': '16px', color: 'rgb(170, 148, 99)' }}>{item.sales_num} </span>  </span>
                                            <button
                                              style={{
                                                backgroundColor: "#897143",
                                                border: "1px solid #897143",
                                                color: "white",
                                                outline: "none",
                                                padding: "8px 35px",
                                                fontSize: "14px",
                                              }}
                                              onClick={this.nextItemSubmit.bind(
                                                this,
                                                item
                                              )}
                                            >
                                              立即预订
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              );
                            }
                          )}
                        </div>

                      </div>
                    ))
                  }
                </div>) : (
                  <div className="">
                    <p
                      style={{
                        color: "#897143",
                        fontSize: "24px",
                        fontWeight: "600",
                        marginTop: "20px",
                      }}
                      className="py-5 text-center"
                    >
                      {cate_name}
                    </p>
                    <div style={{ color: "#666666" }} className="py-5 px-12">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: cate_content,
                        }}
                      ></div>
                    </div>
                    {/*  */}
                    <div className="">
                      {(product && product.length > 0 ? product : []).map(
                        (item, index) => {
                          return (
                            <div className="" key={index}>
                              {(index + 1) % 2 == 0 ? (
                                <div className="grid grid-cols-8 gap-4 my-6">
                                  <div className="col-start-1 col-end-4" >
                                    <div className="space-y-4">
                                      <div className="flex flex-col py-5 " onClick={this.nextItemDetails.bind(this, item)}>
                                        <h3
                                          className="text-lg leading-6 font-medium text-gray-900"
                                          style={{ color: "#453A2C" }}
                                        >
                                          {item.product_name}
                                        </h3>
                                        <div className="mt-1 max-w-2xl text-sm text-gray-500" style={{ color: '#666666', 'lineHeight': '24px' }}>
                                          <div
                                            dangerouslySetInnerHTML={{
                                              __html: item.product_info,
                                            }}
                                          ></div>
                                        </div>
                                      </div>
                                      <div className="flow-root py-2" onClick={this.nextItemDetails.bind(this, item)}>
                                        <div className="w-full">
                                          <div className=" ">
                                            <div
                                              className="table-row"
                                              style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                flexWrap: 'wrap',
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                              }}
                                            >
                                              {item.specs.map((specItem, specIndex) => {
                                                return (
                                                  <div
                                                    key={specIndex}
                                                    className="table-cell  mt-2"
                                                    style={{
                                                      display: "flex",
                                                      flexDirection: "column",
                                                      alignItems: "center",
                                                      padding: "10px 10px",
                                                      borderRight:
                                                        "1px solid #f0f0f0",
                                                      width: "33%",
                                                      height: "90px"
                                                    }}
                                                  >
                                                    <img
                                                      style={{
                                                        width: "30px",
                                                        height: "30px",
                                                      }}
                                                      src={specItem.spec_pic}
                                                      alt=""
                                                    />
                                                    <span
                                                      style={{
                                                        color: "#333333",
                                                        fontSize: "11px",
                                                      }}
                                                    >
                                                      {specItem.spec_name}
                                                    </span>
                                                  </div>
                                                );
                                              })}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="flex flex-row my-2 justify-between"
                                        style={{
                                          alignItems: "center",
                                          color: "#333333",
                                          fontSize: "14px",
                                        }}
                                      >
                                        <span>    <span style={{ 'fontSize': '16px', color: 'rgb(170, 148, 99)' }}>{item.sales_num} </span>  </span>
                                        <button
                                          style={{
                                            backgroundColor: "#897143",
                                            border: "1px solid #897143",
                                            color: "white",
                                            outline: "none",
                                            padding: "8px 35px",
                                            fontSize: "14px",
                                          }}
                                          onClick={this.nextItemSubmit.bind(
                                            this,
                                            item
                                          )}
                                        >
                                          立即预订
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-start-4 col-span-8 h-100" onClick={this.nextItemDetails.bind(this, item)}>
                                    <div
                                      className="group relative"
                                      style={{ height: "100%" }}
                                    >
                                      <div
                                        style={{ height: "100%" }}
                                        className="relative w-full h-400 bg-white overflow-hidden group-hover:opacity-75 sm:aspect-w-2 sm:aspect-h-1 sm:h-64 lg:aspect-w-1 lg:aspect-h-1"
                                      >
                                        <img
                                          src={item.product_pic}
                                          alt={item.product_name}
                                          style={{ height: "480px" }}
                                          className="w-full h-full object-center object-cover"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div className="grid grid-cols-8 gap-4 my-6">
                                  <div className="col-start-1 col-span-5 h-100" onClick={this.nextItemDetails.bind(this, item)}>
                                    <div
                                      className="group relative"
                                      style={{ height: "100%" }}
                                    >
                                      <div
                                        style={{ height: "100%" }}
                                        className="relative w-full h-400 bg-white overflow-hidden group-hover:opacity-75 sm:aspect-w-2 sm:aspect-h-1 sm:h-64 lg:aspect-w-1 lg:aspect-h-1"
                                      >
                                        <img
                                          src={item.product_pic}
                                          alt={item.product_name}
                                          style={{ height: "480px" }}
                                          className="w-full h-full object-center object-cover"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-start-6 col-end-9" onClick={this.nextItemDetails.bind(this, item)}>
                                    <div className="space-y-4">
                                      <div className="flex flex-col py-5 ">
                                        <h3
                                          className="text-lg leading-6 font-medium text-gray-900"
                                          style={{ color: "#453A2C" }}
                                        >
                                          {item.product_name}
                                        </h3>
                                        <div className="mt-1 max-w-2xl text-sm text-gray-500" style={{ color: "#666666", 'lineHeight': '24px' }}>
                                          <div
                                            dangerouslySetInnerHTML={{
                                              __html: item.product_info,
                                            }}
                                          ></div>
                                        </div>
                                      </div>
                                      <div className="flow-root py-2" onClick={this.nextItemDetails.bind(this, item)}>
                                        <div className="w-full">
                                          <div className=" ">
                                            <div
                                              className="table-row"
                                              style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                flexWrap: 'wrap',
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                              }}
                                            >
                                              {item.specs.map((specItem, specIndex) => {
                                                return (
                                                  <div
                                                    key={specIndex}
                                                    className="table-cell mt-2"
                                                    style={{
                                                      display: "flex",
                                                      flexDirection: "column",
                                                      alignItems: "center",
                                                      padding: "10px 10px",
                                                      borderRight:
                                                        "1px solid #f0f0f0",
                                                      width: "33%",
                                                      height: "90px"
                                                    }}
                                                  >
                                                    <img
                                                      style={{
                                                        width: "30px",
                                                        height: "30px",
                                                      }}
                                                      src={specItem.spec_pic}
                                                      alt=""
                                                    />
                                                    <span
                                                      style={{
                                                        color: "#333333",
                                                        fontSize: "11px",
                                                      }}
                                                    >
                                                      {specItem.spec_name}
                                                    </span>
                                                  </div>
                                                );
                                              })}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="flex flex-row my-2 justify-between"
                                        style={{
                                          alignItems: "center",
                                          color: "#333333",
                                          fontSize: "14px",
                                        }}
                                      >
                                        <span>    <span style={{ 'fontSize': '16px', color: 'rgb(170, 148, 99)' }}>{item.sales_num} </span>  </span>
                                        <button
                                          style={{
                                            backgroundColor: "#897143",
                                            border: "1px solid #897143",
                                            color: "white",
                                            outline: "none",
                                            padding: "8px 35px",
                                            fontSize: "14px",
                                          }}
                                          onClick={this.nextItemSubmit.bind(
                                            this,
                                            item
                                          )}
                                        >
                                          立即预订
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          );
                        }
                      )}
                    </div>

                  </div>
                )
              }


              {/* <div
                className="flex justify-center align-center my-10"
                style={{}}
              >
                <button
                  style={{
                    border: "none",
                    color: "#333333",
                    outline: "none",
                    padding: "10px 50px",
                    fontSize: "12px",
                    backgroundColor: "#F1F1F1",
                  }}
                >
                  更多
                </button>
              </div> */}
            </div>
          </div>

          <div className="py-10 flex items-center justify-center">
            <button
              style={{
                color: "#333333",
                outline: "none",
                padding: "8px 35px",
                width: '120px',
                fontSize: "13px",
                backgroundColor: '#F1F1F1'
              }}
              onClick={this.nextlistSubmit.bind(this)}
            >
              更多
            </button>
          </div>
          <Contact />
          {/* <Footer /> */}
          {/* <Footer config={this.state.sysConfig} /> */}
        </div>
      </div>
    );
  }
}

export default HotelReservation;
