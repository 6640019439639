import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import Home from "./pages/home";
import HotelReservation from "./pages/hotelReservation";
import ContactUs from "./pages/contactUs";
import HotelIntroduction from "./pages/hotelIntroduction";
import MyOrder from "./pages/myOrder";
import Register from "./pages/register";
import MyInfo from "./pages/myInfo";
import Login from "./pages/login";
import Forgetpassword from "./pages/forgetpassword";
import HousingResourcesList from "./pages/housingResourcesList";
import ScheduledSubmission from "./pages/scheduledSubmission";
import ListingDetails from "./pages/listingDetails";
import FoodDetails from "./pages/foodDetails";
import VideoList from "./pages/videoList";
import WxArticle from "./pages/wxArticle";
import WxArticleDetail from "./pages/wxArticleDetail";


export default function Content(props: any) {
  return (
    <main>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/MyOrder" component={MyOrder} />
        <Route path="/Login" component={Login} />
        <Route path="/HotelReservation" component={HotelReservation} />
        <Route path="/ContactUs" component={ContactUs} />
        <Route path="/HotelIntroduction" component={HotelIntroduction} />
        <Route path="/Register" component={Register} />
        <Route path="/MyInfo" component={MyInfo} />
        <Route path="/Forgetpassword" component={Forgetpassword} />
        <Route path="/HousingResourcesList/:id" component={HousingResourcesList} />
        <Route path="/ScheduledSubmission/:id" component={ScheduledSubmission} />
        <Route path="/ListingDetails/:id" component={ListingDetails} />
        <Route path="/FoodDetails/:id" component={FoodDetails} />
        <Route path="/VideoList" component={VideoList} />
        <Route path="/WxArticle" component={WxArticle} />
        <Route path="/WxArticleDetail/:id" component={WxArticleDetail} />
        
      </Switch>
    </main>
  );
}
