import React from "react";
import axios from "axios";
import { DatePicker, Select, Carousel, Modal } from "antd";
import "./index.css";

import Contact from "../../components/Contact";

import button_left from "../../assets/images/button_left@2x.png";

import button_right from "../../assets/images/button_right@2x.png";

const { RangePicker } = DatePicker;
const { info } = Modal;

class ListingDetails extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      homeConfig: {},
      cxProInfo: {},

      start_time: "",
      end_time: "",
      houseType: "",
      productList: [],
      productSelect: [],
      bannerList: [],
      articleList: [],
      // 
      tjProList: [],
      tj_content: '',
      proInfo: {}
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.initData(this.props.match.params.id);
  }

  initData(id) {
    axios({
      method: "post",
      url: "/Index/index/getProductView",
      data: {
        product_id: id
      },
    }).then((res) => {
      console.log(res.data.data, '21');
      this.setState({
        tjProList: res.data.data.tjProList,
        tj_content: res.data.data.tj_content,
        proInfo: res.data.data.proInfo,
      })
      var articleList = [];
      let articleArr = res.data.data.tjProList;
      for (var i = 0; i < articleArr.length; i += 5) {
        let result = articleArr.slice(i, i + 5);
        // @ts-ignore
        articleList.push(result);
      }
      console.log(articleList, "articleList");
      this.setState({
        articleList: articleList,
        bannerList: res.data.data.proInfo.photo
      });
    });
  }

  // 立即预订
  nextItemSubmit(item: any) {
    this.props.history.push(`/ScheduledSubmission/${item.product_id}`);
  }

  nextItemDetails(item: any) {
    this.initData(item.product_id)
    // this.props.history.push(`/ListingDetails/${item.product_id}`);
  }

  imgDeatil(val) {
    if (val === 0) {
      return "col-start-1 col-span-4";
    } else if (val === 1) {
      return "col-start-5 col-span-2";
    } else if (val === 2) {
      return "col-start-7 col-span-2";
    } else if (val === 3) {
      return "col-start-1 col-span-4";
    } else if (val === 4) {
      return "col-start-5 col-span-2";
    } else if (val === 5) {
      return "col-start-7 col-span-2";
    }
  }
  showModal = () => {
    info({
      closable: true,
      width: 800,
      icon: null,
      okText: '确定',
      content: (<div>
        <video
          src={this.state.homeConfig.video_url}
          className="w-full"
          style={{
            height: "400px",
          }}
          controls
        ></video>
      </div>),
    });
  };
  render() {

    let { homeConfig, cxProInfo, productSelect, bannerList, articleList, tjProList, tj_content, proInfo } =
      this.state;

    return (
      <div className="Home relative">
        <div
          id="carouselExampleInterval"
          className="carousel slide h-120"
          data-bs-ride="carousel"
        >
          <div className="carousel-inner h-120">
            {(bannerList && bannerList.length > 0 ? bannerList : []).map(
              (item, index) => (
                <div
                  className={`carousel-item ${index === 0 ? "active" : null}`}
                  key={index}
                  data-bs-interval="3000"
                >
                  <div className="group relative" style={{ height: "100%" }}>
                    <div
                      style={{ height: "100%" }}
                      className="relative w-full h-400 bg-white overflow-hidden group-hover:opacity-75 sm:aspect-w-2 sm:aspect-h-1 sm:h-64 lg:aspect-w-1 lg:aspect-h-1"
                    >
                      <img
                        src={item}
                        alt=""
                        style={{ height: "500px" }}
                        className="w-full object-center object-cover"
                      />
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
          {/* <div className="carousel-indicators">
            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
          </div> */}
          <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>

        {/* <Address homeConfig={homeConfig} /> */}
        <div>

          <div className="space-y-4" style={{ borderBottom: '1px solid #DFDFDF' }}>
            <div
              className="flow-root px-4 py-5 sm:px-6"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <h3
                className="text-lg leading-6 font-medium text-gray-900"
                style={{ color: "#897143", fontSize: '26px' }}
              >
                {proInfo.product_name}
              </h3>
              <br />
              <div className="mt-1 text-sm text-gray-500" style={{ width: '80%' }}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: proInfo.content,
                  }}
                ></div>
              </div>
            </div>
            <div className="flow-root py-10">
              <div className="flex flex-row justify-center my-10">
                <button
                  className="py-2 px-20"
                  style={{
                    backgroundColor: "#897143",
                    color: "white",
                    fontSize: "14px",
                  }}
                  onClick={this.nextItemSubmit.bind(this, proInfo)}
                >
                  立即预定
                </button>
              </div>
            </div>
          </div>
          {/*  */}
          <div className="bg-white  py-5 ">
            <div
              className="flex flex-row px-4 py-3 sm:px-6 justify-around"
              style={{
                alignItems: "center",
              }}
            >
              <h3
                className="text-lg leading-6 font-medium text-gray-900"
                style={{ color: "#333333", fontSize: '26px' }}
              >
                推荐客房
              </h3>
              <div className="mt-1 max-w-2xl text-sm text-gray-500">
                <div
                  dangerouslySetInnerHTML={{
                    __html: tj_content,
                  }}
                ></div>
              </div>
            </div>
            <div className="">
              <Carousel autoplay>
                {(articleList && articleList.length > 0 ? articleList : []).map(
                  (item, index) => (
                    <div key={index} className="py-8 px-4 sm:py-10 sm:px-6  lg:px-8">
                      <div className="flex flex-row justify-between" style={{}}>
                        {item.map((product, productIndex) => (
                          <a
                            key={productIndex}
                            className="group p-2"
                            style={{ width: "40%" }}
                            onClick={this.nextItemDetails.bind(this, product)}
                          >
                            <div className="w-full aspect-w-1 aspect-h-1 bg-gray-200 overflow-hidden xl:aspect-w-7 xl:aspect-h-8">
                              <img
                                src={product.product_pic}
                                alt={""}
                                style={{
                                  width: '400px',
                                  height: '300px'
                                }}
                                className="w-full h-full object-center object-cover group-hover:opacity-75"
                              />
                            </div>
                            <h3
                              className="mt-4 text-gray-700"
                              style={{ color: "#453A2C", fontSize: "20px" }}
                            >
                              {product.product_name}
                            </h3>
                            <div
                              className="mt-1 text-lg font-medium text-gray-900"
                              style={{ color: "#666666", fontSize: "13px" }}
                            >
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: product.product_info,
                                }}
                              ></div>
                            </div>
                          </a>
                        ))}
                      </div>
                    </div>
                  )
                )}
              </Carousel>

              {/* articleList */}
              <div className="flex flex-row justify-center my-4 items-center">
                <img
                  className="w-8 h-8 mx-3"
                  src={button_left}
                  alt=""
                />
                <img
                  className="w-8 h-8 mx-3"
                  src={button_right}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>


        <Contact homeConfig={homeConfig} />

      </div>
    );
  }
}

export default ListingDetails;
