import React from "react";
import moment from "moment";
import "./index.less";
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';
import { DatePicker, message } from "antd";

import "tailwindcss/tailwind.css";

import axios from "axios";

class ScheduledSubmission extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      type: "1",
      real_name: "",
      phone: "",
      email: "",
      city: "",
      address: "",
      start_time: moment(new Date()).format("YYYY-MM-DD"),
      end_time: moment(new Date()).add(1, 'd').format("YYYY-MM-DD"),
      remark: '',
      order_price: '0',
      product_info: {
        product_price: '',
        product_name: '',
      }
    };
  }


  componentDidMount() {
    console.log(this.state.start_time, 'start_time')
    window.scrollTo(0, 0);
    this.initData()
    this.getOrderPrice({
      start_time: this.state.start_time,
      end_time: this.state.end_time
    })
  }

  initData() {

    axios({
      method: "post",
      url: `/Index/index/getProductView`,
      data: {
        token: window.localStorage.getItem("loginInfoToken"),
        product_id: this.props.match.params.id,
      },
    }).then((res) => {
      if (res.data.code == 200) {
        // product_price
        this.setState({
          product_info: res.data.data.proInfo
        })
      }
    });
  }

  setActive(val: any) {
    this.setState({
      type: val,
    });
  }

  createOrder() {
    axios({
      method: "post",
      url: `/Index/user/createOrder`,
      data: {
        token: window.localStorage.getItem("loginInfoToken"),
        product_id: this.props.match.params.id,
        real_name: this.state.real_name,
        phone: this.state.phone,
        email: this.state.email,
        city: this.state.city,
        address: this.state.address,
        start_time: this.state.start_time,
        end_time: this.state.end_time,
        remark:this.state.remark,
      },
    }).then((res) => {
      if (res.data.code == 200) {
        message.success(res.data.message);
      }
    });
  }

  getOrderPrice({
    start_time,
    end_time
  }) {
    axios({
      method: "post",
      url: `/Index/user/confirm`,
      data: {
        token: window.localStorage.getItem("loginInfoToken"),
        product_id: this.props.match.params.id,
        start_time: start_time,
        end_time: end_time,
      },
    }).then((res) => {
      if (res.data.code == 200) {
        this.setState({
          order_price: res.data.data.order_price.toFixed(2)
        })
      }
    });
  }

  changeStartDate(date, dateString) {
    this.setState({
      start_time: dateString,
    });
    //@ts-ignore
    this.getOrderPrice({
      start_time: dateString,
      end_time: this.state.end_time,
    })
  }
  changeEndDate(date, dateString) {
    this.setState({
      end_time: dateString,
    });
    //@ts-ignore
    this.getOrderPrice({
      start_time: this.state.start_time,
      end_time: dateString,
    })
  }

  render() {
    let { order_price, product_info } = this.state;
    return (
      <div className="ScheduledSubmission" style={{ backgroundColor: '#FBFBFC' }}>
        <div
          className="MyInfo_content flex flex-row justify-center  py-4"
          style={{ width: "80%", margin: "auto", height: "900px" }}
        >
          <div
            className="flex flex-col w-6/12 py-4 px-6"
            style={{ backgroundColor: "#FBFBFC" }}
          >
            <p style={{ color: "#453A2C", fontSize: "18px" }}>入住信息</p>
            <div
              className="flex flex-row py-6"
              style={{ alignItems: "center", borderBottomColor: "#FBFBFC" }}
            >
              <div className="flex flex-col w-4/12">
                <span style={{ color: "#897143" }}>入住时间</span>
                <span
                  className="mt-2"
                  style={{ color: "#333333", fontSize: "13px" }}
                >
                  <DatePicker
                    locale={locale}
                    bordered={false}
                    style={{ padding: "10px 15px 10px 0px" }}
                    suffixIcon={null}
                    defaultValue={moment(new Date(), "YYYY/MM/DD")}
                    placeholder="请选择开始日期"
                    onChange={this.changeStartDate.bind(this)}
                  />
                </span>
              </div>
              <div className="flex flex-col py-6 w-4/12">
                <span style={{ color: "#897143" }}>离店时间</span>
                <span
                  className="mt-2"
                  style={{ color: "#333333", fontSize: "13px" }}
                >
                  <DatePicker
                    locale={locale}
                    bordered={false}
                    style={{ padding: "10px 15px 10px 0px" }}
                    suffixIcon={null}
                    defaultValue={moment(new Date(), "YYYY/MM/DD").add(1, 'd')}
                    placeholder="请选择结束日期"
                    onChange={this.changeEndDate.bind(this)}
                  />
                </span>
              </div>
            </div>
            <div
              className="flex flex-col  py-6"
              style={{ borderBottomColor: "#FBFBFC" }}
            >
              <p style={{ color: "#453A2C", fontSize: "18px" }}>

                {product_info.product_name}
              </p>
              <p style={{ color: "#453A2C", fontSize: "18px" }}> {product_info.product_price}</p>
            </div>
            <div className="flex flex-row justify-between py-6">
              <span style={{ color: "#453A2C" }}>合计：</span>
              <span style={{ color: "#897143", fontSize: "17px" }}>

                {order_price}
              </span>
            </div>
          </div>
          <div className="flex flex-col w-6/12 py-4 px-6" style={{ backgroundColor: '#FFFFFF' }}>
            <div className="flex flex-col px-6">
              <p style={{ color: "#453A2C", fontSize: "18px" }}>联系信息</p>
              <div className="flex flex-col mx-10 py-4">
                <div
                  className="flex flex-row my-4"
                  style={{ alignItems: "center" }}
                >
                  <label
                    htmlFor="real_name"
                    className="w-20 mr-4"
                    style={{ fontSize: "15px" }}
                  >
                    <span style={{ color: 'red' }}>* </span>
                    姓名
                  </label>
                  <input
                    id="real_name"
                    name="real_name"
                    type="text"
                    ref="real_name"
                    autoComplete="off"
                    style={{
                      backgroundColor: "transparent",
                      border: "1px solid #333333",
                    }}
                    onBlur={(e) => {
                      this.setState({
                        real_name: (e.target as any).value,
                      });
                    }}
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-300 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="请输入用户名"
                  />
                </div>

                <div
                  className="flex flex-row my-4"
                  style={{ alignItems: "center" }}
                >
                  <label
                    htmlFor="phone"
                    className="w-20 mr-4"
                    style={{ fontSize: "15px" }}
                  >
                    <span style={{ color: 'red' }}>* </span>
                    手机号
                  </label>
                  <input
                    id="phone"
                    name="phone"
                    type="text"
                    ref="phone"
                    autoComplete="off"
                    style={{
                      backgroundColor: "transparent",
                      border: "1px solid #333333",
                    }}
                    onBlur={(e) => {
                      this.setState({
                        phone: (e.target as any).value,
                      });
                    }}
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-300 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="请输入手机号"
                  />
                </div>

                <div
                  className="flex flex-row my-4"
                  style={{ alignItems: "center" }}
                >
                  <label
                    htmlFor="email"
                    className="w-20 mr-4"
                    style={{ fontSize: "15px" }}
                  >
                     <span style={{ color: 'red' }}>&nbsp;&nbsp;&nbsp;</span>
                    邮箱
                  </label>
                  <input
                    id="email"
                    name="email"
                    type="text"
                    ref="email"
                    autoComplete="off"
                    style={{
                      backgroundColor: "transparent",
                      border: "1px solid #333333",
                    }}
                    onBlur={(e) => {
                      this.setState({
                        email: (e.target as any).value,
                      });
                    }}
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-900 placeholder-gray-300 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="请输入邮箱"
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col px-6">
              <p style={{ color: "#453A2C", fontSize: "18px" }}>地址</p>
              <div className="flex flex-col mx-10 py-4">
                <div
                  className="flex flex-row my-4"
                  style={{ alignItems: "center" }}
                >
                  <label
                    htmlFor="city"
                    className="w-20 mr-4"
                    style={{ fontSize: "15px" }}
                  >
                    <span style={{ color: 'red' }}>&nbsp;&nbsp;&nbsp;</span>
                    城市
                  </label>
                  <input
                    id="city"
                    name="city"
                    type="text"
                    ref="city"
                    autoComplete="off"
                    style={{
                      backgroundColor: "transparent",
                      border: "1px solid #333333",
                    }}
                    onBlur={(e) => {
                      this.setState({
                        city: (e.target as any).value,
                      });
                    }}
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-300 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="请输入城市"
                  />
                </div>

                <div
                  className="flex flex-row my-4"
                  style={{ alignItems: "center" }}
                >
                  <label
                    htmlFor="iphone"
                    className="w-20 mr-4"
                    style={{ fontSize: "15px" }}
                  >
                    <span style={{ color: 'red' }}>&nbsp;&nbsp;&nbsp;</span>
                    地址
                  </label>
                  <input
                    id="address"
                    name="address"
                    type="text"
                    ref="address"
                    autoComplete="off"
                    style={{
                      backgroundColor: "transparent",
                      border: "1px solid #333333",
                    }}
                    onBlur={(e) => {
                      this.setState({
                        address: (e.target as any).value,
                      });
                    }}
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-300 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="请输入地址"
                  />
                </div>

                <div
                  className="flex flex-row my-4"
                  style={{ alignItems: "center" }}
                >
                  <label
                    htmlFor="iphone"
                    className="w-20 mr-4"
                    style={{ fontSize: "15px" }}
                  >
                    <span style={{ color: 'red' }}>&nbsp;&nbsp;&nbsp;</span>
                    备注
                  </label>
                  <textarea
                    id="remark"
                    name="remark"
                    ref="remark"
                    autoComplete="off"
                    style={{
                      backgroundColor: "transparent",
                      border: "1px solid #333333",
                    }}
                    onBlur={(e) => {
                      this.setState({
                        remark: (e.target as any).value,
                      });
                    }}
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-300 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="请输入备注"
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-row justify-center my-10">
              <button
                className="py-2 px-20"
                style={{
                  backgroundColor: "#897143",
                  color: "white",
                  fontSize: "12px",
                }}
                onClick={this.createOrder.bind(this)}
              >
                保存
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ScheduledSubmission;
