import React, { Component } from "react";
import axios from "axios";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Header from "./components/Header";
import Content from "./Content";

import Footer from "./components/Footer";
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'

class App extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      sysConfig: {},
      footMenu:{}
    };
  }
  componentDidMount() {
   
    axios({
      method: "post",
      url: "/Index/index/getSysConfig",
      data: {},
    }).then((res) => {
      console.log(res);
      this.setState({
        sysConfig: res.data.data.sysConfig,
        footMenu:res.data.data.footMenu
      });
      document.title = res.data.data.sysConfig.web_name;
    });
  }
  render(): React.ReactNode {
    let { cateTree,articleCateTree,contactTree,aboutTree } = this.state.footMenu
    return (
      <div>
        <Header config={this.state.sysConfig} />
        <Content />
        <Footer config={this.state.sysConfig}  menu={this.state.footMenu} cateTree={cateTree} articleCateTree={articleCateTree}  contactTree={contactTree} aboutTree={aboutTree} />
      </div>
    );
  }
}

export default App;
