import React, { useState, useEffect } from "react";

import "./index.less";
import axios from "axios";
import phoneurl from "../../assets/images/icon_phone_1@2x.png";
import { message } from "antd";
const name = "Travel";

export default function Contact(props: any) {
  const [phone, setPhone] = useState("");
  const [state, setState] = useState({
    ly_content: "",
  });
  const setIphone = () => {
    axios({
      method: "post",
      url: "/Index/index/createMsg",
      data: {
        phone: phone,
      },
    }).then((res) => {
          if(res.data.code == 200){
         message.success(res.data.message);
      }
    });
  };

  const initData = () => {
    axios({
      method: "post",
      url: "/Index/index/getSysConfig",
      data: {},
    }).then((res) => {
      setState({
        ly_content: res.data.data.sysConfig.ly_content,
      });
    });
  };

  useEffect(() => {
    initData();
  }, []);
  return (
    <div className="bg-gray-100">
      {/* pc */}
      <div className="col-start-1 col-span-8 ...">
        <div key={name} className="group relative">
          <div
            style={{ backgroundColor: "#897143", alignItems: "center" }}
            className="flex flex-col justify-center relative w-full h-80 overflow-hidden  sm:aspect-w-2 sm:aspect-h-1 sm:h-64 lg:aspect-w-1 lg:aspect-h-1"
          >
            <p style={{ color: "#FFFFFF", fontSize: "30px" }}>婚宴场地预订</p>
            <div style={{ color: "#FFFFFF", fontSize: "13px",'marginTop':'14px' }} >
               <div
                  dangerouslySetInnerHTML={{
                    __html:state.ly_content,
                  }}
                ></div>
            </div>
            <div className="flex flex-row my-8">
              <button
                style={{
                  padding: "10px 10px",
                  background: "white",
                  borderRight: "1px solid #f0f0f0",
                  height: "40px",
                  color: "white",
                  fontSize: "12px",
                }}
              >
                <img
                  style={{ width: "14px", height: "14px" }}
                  src={phoneurl}
                  alt=""
                />
              </button>
              <input
                type="text"
                onChange={(e) => {
                  console.log(e.target.value, "e.target.value");
                  setPhone(e.target.value);
                }}
                style={{
                  fontSize: "12px",
                  width: "220px",
                  height: "40px",
                  border: "none",
                  outline: "none",
                  padding: "6px 8px",
                }}
              />
              <button
                onClick={setIphone}
                style={{
                  width: "110px",
                  height: "41px",
                  background: "#1b1b1b",
                  color: "white",
                  fontSize: "12px",
                }}
              >
                联系我们
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* mobile */}
      {/* <div className="col-start-1 col-span-8">
        <div key={name} className="group relative">
          <div
            style={{ backgroundColor: "#897143", alignItems: "center" }}
            className="flex flex-col justify-center relative w-full h-80 overflow-hidden group-hover:opacity-75 sm:aspect-w-2 sm:aspect-h-1 sm:h-64 lg:aspect-w-1 lg:aspect-h-1 px-8"
          >
            <p style={{ color: "#FFFFFF", fontSize: "24px" }} className="my-4">
              上饶国际大酒店
            </p>
            <p style={{ color: "#FFFFFF", fontSize: "12px" }}>{}</p>
            <div className="flex flex-row mt-20 w-full">
              <button
                style={{
                  padding: "10px 10px",
                  background: "white",
                  borderRight: "1px solid #f0f0f0",
                  height: "40px",
                  color: "white",
                  fontSize: "12px",
                }}
              >
                <img
                  style={{ width: "14px", height: "14px" }}
                  src={phoneurl}
                  alt=""
                />
              </button>
              <input
                type="text"
                placeholder="留下您的手机号吧～"
                onChange={(e) => {
                  console.log(e.target.value, "e.target.value");
                  setPhone(e.target.value);
                }}
                style={{
                  fontSize: "12px",
                  height: "40px",
                  border: "none",
                  outline: "none",
                  padding: "6px 8px",
                  width: "calc(100% - 128px)",
                }}
              />
              <button
                style={{
                  width: "80px",
                  height: "41px",
                  background: "#1b1b1b",
                  color: "white",
                  fontSize: "12px",
                }}
                onClick={setIphone}
              >
                联系我们
              </button>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}
