import React from "react";
import axios from 'axios'
import { Modal } from "antd";
import "./index.css";



const { info } = Modal;


class wxArticleDetail extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            wxArticleDetail: [],
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.initData()
    }

    initData() {
        axios({
            method: "get",
            url: `/Index/index/articleViewOther?id=${this.props.match.params.id}`,
        }).then((res) => {
            this.setState({
                wxArticleDetail: res.data.data,
            })
        });
    }

    render() {
        let { wxArticleDetail } = this.state
        return (
            <div className="wxArticleDetail">
                {/*  */}
                <div
                    id="carouselExampleInterval"
                    className="carousel slide h-120"
                    data-bs-ride="carousel"
                >
                    <div className="carousel-inner h-120">
                        <div
                            className={`carousel-itemx`}
                            data-bs-interval="3000"
                        >
                            <div className="group relative" style={{ height: "100%" }}>
                                <div
                                    style={{ height: "100%" }}
                                    className="relative w-full h-400 bg-white overflow-hidden group-hover:opacity-75 sm:aspect-w-2 sm:aspect-h-1 sm:h-64 lg:aspect-w-1 lg:aspect-h-1"
                                >
                                    <img
                                        src={'https://huayunjd.oss-cn-hangzhou.aliyuncs.com/test/news.png'}
                                        alt=""
                                        style={{ height: "400px", }}
                                        className="w-full object-center object-cover"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/*  */}
                <div className="wxArticleDetail_content">
                    <p className="WxArticle_item_info_title">
                        {wxArticleDetail.title}
                    </p>
                    <p className="WxArticle_item_info_date">
                        {wxArticleDetail.create_time}
                    </p>
                    <div className="WxArticle_item_info_text">
                        <div
                            dangerouslySetInnerHTML={{
                                __html: wxArticleDetail.content,
                            }}
                        ></div>
                    </div>
                </div>
            </div>
        );
    }
}

export default wxArticleDetail;
