import React, { useState, useEffect } from "react";
import axios from "axios";
import { Popover, message } from 'antd';
import wechat from "../../assets/images/wechat@2x.png";
import sina from "../../assets/images/sina@2x.png";
import logo from "../../assets/images/logo2@2x.png";
import qq from "../../assets/images/qq@2x.png";

import dingwei from "../../assets/images/icon_address@2x.png";
import phone from "../../assets/images/icon_l_phone@2x.png";

export default function Footer(props) {
  const [state, setstate] = useState({
    phone: ''
  })
  const setIphone = () => {
    axios({
      method: "post",
      url: "/Index/index/createMsg",
      data: {
        phone: state.phone,
      },
    }).then((res) => {
      if (res.data.code == 200) {
        message.success(res.data.message);
      }
    });
  };
  useEffect(() => { }, []);
  let { config, contactTree, aboutTree, articleCateTree, cateTree } = props;
  return (
    <div style={{ backgroundColor: "#FCFCFC" }}>
      <div className="flex flex-row justify-between" style={{
        backgroundColor: '#aa9463'
      }}>
        <div className="flex flex-col w-6/12 px-20 py-10">
          <p
            className="font-medium  text-gray-50"
            style={{ fontSize: "15px" }}
          >
            保持联系
          </p>
          <div className="mt-4 flex flex-row justify-between" style={{ 'backgroundColor': 'white' }}>
            <input type="text"
              className=""
              style={{
                padding: '10px 15px',
                // width: '380px',
                border: 'none',
                outline: 'none'
              }}
              onChange={(e: any) => {
                setstate(e.target.value);
              }}
              placeholder="输入您的联系电话" />
            <button className="" style={{ width: '120px', backgroundColor: 'black', color: 'white', fontSize: '12px' }}
              onClick={setIphone}
            >
              提交
            </button>
          </div>
        </div>
        <div className="flex flex-col w-6/12 px-20 py-10">
          <p
            className="font-medium  text-gray-50"
            style={{ fontSize: "15px" }}
          >
            关注我们
          </p>
          <div className="mt-4 flex flex-row items-center">
            <Popover title={null} content={() => (<div>
              <img style={{ width: '120px', height: '120px' }} src={config.wechat_url} alt="" />
            </div>)} trigger="hover">
              <a href={config.wechat_url}>
                <img
                  src={wechat}
                  alt=""
                  className="object-center object-cover w-6 h-6 mx-2"
                />
              </a>
            </Popover>
            <a href={config.qq_url}>
              <img
                src={qq}
                alt=""
                className="object-center object-cover w-6 h-6 mx-2"
              />
            </a>
            <a href={config.weibo_url}>
              <img
                src={sina}
                alt=""
                className="object-center object-cover w-6 h-6 mx-2"
              />
            </a>
          </div>
        </div>
      </div>
      <div className="flex flex-row justify-between py-10" style={{
        backgroundColor: '#85724b'
      }}>
        <div className="flex flex-col w-6/12">
          <div className="flex flex-col">
            <div className="flex flex-col px-20 py-10 w-full">
              <p
                className="font-medium  text-gray-50"
                style={{ fontSize: "15px" }}
              >
                上饶国际大酒店
              </p>
              <div className="mt-4">
                <p className=" text-gray-50">
                  {config.address}
                </p>
                <p className="mt-4  text-gray-50">
                  {config.service_phone}
                </p>
              </div>
            </div>
            <div className="flex flex-col px-20 py-10 w-full">
              <p
                className="font-medium  text-gray-50"
                style={{ fontSize: "15px" }}
              >
                客房预订
              </p>
              <div className="mt-4">
                <p className=" text-gray-50">
                  {config.service_phone}
                </p>
              </div>
            </div>
            <div className="pt-10 px-20">
              <p className="mt-6 mb-6 text-sm  text-gray-50">{config.copy}</p>
            </div>
          </div>
        </div>
        <div className="flex flex-row w-6/12 justify-between py-10 px-20">
          <div>
            <p
              className="font-medium  text-gray-50"
              style={{ fontSize: "15px" }}
            >
              酒店预订
            </p>
            <ul role="list" className="mt-6 space-y-6 sm:mt-4 sm:space-y-4">
              {(cateTree && cateTree.length > 0 ? cateTree : []).map(
                (item) => (
                  <li key={item.name} className="flex">
                    <a href={`/#/HousingResourcesList/${item.id}`} className=" text-gray-50">
                      {item.name}
                    </a>
                  </li>
                )
              )}
            </ul>
          </div>
          <div>
            <p
              className="font-medium  text-gray-50"
              style={{ fontSize: "15px" }}
            >
              酒店动态
            </p>
            <ul role="list" className="mt-6 space-y-6 sm:mt-4 sm:space-y-4">
              {(articleCateTree && articleCateTree.length > 0
                ? articleCateTree
                : []
              ).map((item) => (
                <li key={item.name} className="flex">
                  <a href={`/#/ScheduledSubmission/${item.id}`} className=" text-gray-50">
                    {item.name}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <p
              className="font-medium  text-gray-50"
              style={{ fontSize: "15px" }}
            >
              关于我们
            </p>
            <ul role="list" className="mt-6 space-y-6 sm:mt-4 sm:space-y-4">
              {(aboutTree && aboutTree.length > 0 ? aboutTree : []).map(
                (item) => (
                  <li key={item.name} className="flex">
                    <a
                      href="/#/HotelIntroduction"
                      className=" text-gray-50"
                    >
                      {item.name}
                    </a>
                  </li>
                )
              )}
            </ul>
          </div>
          <div>
            <p
              className="font-medium  text-gray-50"
              style={{ fontSize: "15px" }}
            >
              联系我们
            </p>
            <ul role="list" className="mt-6 space-y-6 sm:mt-4 sm:space-y-4">
              {(contactTree && contactTree.length > 0 ? contactTree : []).map(
                (item) => (
                  <li key={item.name} className="flex">
                    <a href="/#/HotelReservation" className=" text-gray-50">
                      {item.name}
                    </a>
                  </li>
                )
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
