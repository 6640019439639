export default function CardItem(props: any) {
  let { specList } = props;
  console.log(specList, "specList");
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        flexWrap: "wrap",
        justifyContent: "space-between",
      }}
    >
      {(specList && specList.length > 0
        ? specList
        : []
      ).map((item, index) => (
        <div
          key={index}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "10px 10px",
            borderRight: "1px solid #f0f0f0",
            width: "33%",
            borderBottom: "1px solid #f0f0f0",
          }}
        >
          <img
            style={{ width: "60px", height: "60px" }}
            src={item.spec_pic}
            alt=""
          />
          <span className="my-4" style={{ color: "#333333", fontSize: "11px" }}>
            {item.spec_name}
          </span>
        </div>
      ))}
    </div>
  );
}
