import React from "react";
import axios from "axios";
import { Radio ,message} from "antd";
import "./index.less";

import "tailwindcss/tailwind.css";

import OrderDetail from "./orderDetail";
import mine from "../../assets/images/icon_mine@2x.png";
import leftArrow from "../../assets/images/y_arrow@2x.png";

class MyInfo extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      type: "1",
      userInfo: {},
      orderList: [],
      page: 1,
      pageSize: 10,
      order_status: "",
      sex: 1,
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.initData();
  }

  initData() {
    axios({
      method: "get",
      url: `/Index/user/index?token=${window.localStorage.getItem(
        "loginInfoToken"
      )}`,
    }).then((res) => {
      this.setState({
        userInfo: res.data.data.userInfo,
        sex: res.data.data.userInfo.sex
      });

      (document.getElementById("nickname") as any).value =
        res.data.data.userInfo.nickname;
     
      (document.getElementById("city") as any).value =
        res.data.data.userInfo.city;
      (document.getElementById("address") as any).value =
        res.data.data.userInfo.address;
      (document.getElementById("birthday") as any).value =
        res.data.data.userInfo.birthday;
    });

    axios({
      method: "get",
      url: `/Index/user/orderList?token=${window.localStorage.getItem(
        "loginInfoToken"
      )}&page=${this.state.page}&pageSize=${this.state.pageSize}&order_status=${
        this.state.order_status
      }`,
    }).then((res) => {
      this.setState({
        orderList: res.data.data.orderList,
      });
    });
  }

  setActive(val: any) {
    this.setState({
      type: val,
    });
  }

  cancelReservation(item: any) {
    axios({
      method: "get",
      url: `/Index/user/cancelOrder?token=${window.localStorage.getItem(
        "loginInfoToken"
      )}&order_sn=${item.order_sn}`,
    }).then((res) => {
      console.log(res, "12212");
    });
  }

  updateMyInfo() {
    axios({
      method: "post",
      url: `/Index/user/updateUserInfo`,
      data: {
        token: window.localStorage.getItem("loginInfoToken"),
        nickname: (this.refs.nickname as any).value,
        sex: this.state.sex,
        city: (this.refs.city as any).value,
        address: (this.refs.address as any).value,
        birthday: (this.refs.birthday as any).value,
      },
    }).then((res) => {
           if(res.data.code == 200){
         message.success(res.data.message);
      }
    });
  }

  uploadImage() {
    let formData = new FormData();
    formData.append("image", "files");
    axios({
      method: "post",
      url: `/Index/file/upload`,
      data: formData,
    }).then((res) => {
      console.log(res, "12212");
    });
  }
  saveUploadImage() {
    axios({
      method: "post",
      url: `/Index/user/updateHeaderImg`,
      data: {
        token: window.localStorage.getItem("loginInfoToken"),
        headerimg: "",
      },
    }).then((res) => {
      console.log(res, "12212");
    });
  }

  againReservation(item: any) {
    // 再次预定
    this.props.history.push("/Login");
  }

  nextDetailItem(item: any) {
    // 详情
    // this.props.history.push("/Login");
    this.ifActiveTypeHtml(5);
  }

  nextForgetPassword() {
    this.props.history.push("/Forgetpassword");
  }

  ifActiveTypeHtml(val) {
    let { userInfo, orderList,sex} = this.state;
    console.log(userInfo, "userInfo");
    console.log(sex, "userInfo");
    if (val == 1) {
      return (
        <div>
          <div className="flex flex-row my-4 items-center">
            <label
              htmlFor="nickname"
              className="w-16 mr-4"
              style={{ fontSize: "12px" }}
            >
              用户名
            </label>
            <input
              id="nickname"
              name="nickname"
              type="text"
              // value={userInfo.nickname}
              ref="nickname"
              style={{
                backgroundColor: "transparent",
                border: "1px solid #333333",
              }}
              className="appearance-none rounded-none relative block w-160 px-3 py-2 border border-gray-300 placeholder-gray-300 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              placeholder="请输入用户名"
            />
          </div>

          <div className="flex flex-row my-4 items-center" >
            <label
              htmlFor="sex"
              className="w-16 mr-4"
              style={{ fontSize: "12px" }}
            >
              性别
            </label>
            <Radio.Group onChange={(e)=>{
              this.setState({
                sex:e.target.value
              })
            }} name="radiogroup" defaultValue={sex}>
              <Radio value={1}>男</Radio>
              <Radio value={2}>女</Radio>
              <Radio value={3}>保密</Radio>
            </Radio.Group>
          </div>
          <div className="flex flex-row my-4 items-center">
            <label
              htmlFor="birthday"
              className="w-16 mr-4"
              style={{ fontSize: "12px" }}
            >
              生日
            </label>
            <input
              id="birthday"
              name="birthday"
              type="text"
              ref="birthday"
              // value={userInfo.birthday}
              style={{
                backgroundColor: "transparent",
                border: "1px solid #333333",
              }}
              className="appearance-none rounded-none relative block w-160 px-3 py-2 border border-gray-900 placeholder-gray-300 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              placeholder="请输入生日"
            />
          </div>
          <div className="flex flex-row my-4" style={{ alignItems: "center" }}>
            <label
              htmlFor="city"
              className="w-16 mr-4"
              style={{ fontSize: "12px" }}
            >
              居住城市
            </label>
            <input
              id="city"
              name="city"
              type="text"
              // value={userInfo.city}
              ref="city"
              style={{
                backgroundColor: "transparent",
                border: "1px solid #333333",
              }}
              className="appearance-none rounded-none relative block w-160 px-3 py-2 border border-gray-900 placeholder-gray-300 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              placeholder="请输入城市"
            />
          </div>
          <div className="flex flex-row my-4" style={{ alignItems: "center" }}>
            <label
              htmlFor="address"
              className="w-16 mr-4"
              style={{ fontSize: "12px" }}
            >
              地址
            </label>
            <input
              id="address"
              name="address"
              type="text"
              // value={userInfo.address}
              ref="address"
              style={{
                backgroundColor: "transparent",
                border: "1px solid #333333",
              }}
              className="appearance-none rounded-none relative block w-160 px-3 py-2 border border-gray-900 placeholder-gray-300 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              placeholder="请输入地址"
            />
          </div>

          <div className="flex flex-row justify-center my-10">
            <button
              className="py-2 px-20"
              style={{
                backgroundColor: "#897143",
                color: "white",
                fontSize: "12px",
              }}
              onClick={this.updateMyInfo.bind(this)}
            >
              保存
            </button>
          </div>
        </div>
      );
    } else if (val == 2) {
      return (
        <div
          className="flex flex-col justify-center"
          style={{ alignItems: "center" }}
        >
          <div
            className="flex flex-row my-8 justify-center w-24 h-24 h-18 px-4 py-4"
            style={{ alignItems: "center", backgroundColor: "#F8F8F8" }}
          >
            <img src={userInfo.headerimg} alt="" />
          </div>
          <span className="my-2" style={{ color: "#333333", fontSize: "12px" }}>
            支持jpg、png、jpeg、bmp，图片大小5M以内
          </span>
          <div className="flex flex-row justify-center">
            <button
              className="py-2 px-20"
              style={{
                backgroundColor: "#897143",
                color: "white",
                fontSize: "12px",
              }}
              onClick={this.saveUploadImage.bind(this)}
            >
              保存
            </button>
          </div>
        </div>
      );
    } else if (val == 3) {
      return (
        <div>
          <div className="flex flex-row my-4" style={{ alignItems: "center" }}>
            <label
              htmlFor="iphone"
              className="w-16 mr-4"
              style={{ fontSize: "12px" }}
            >
              密码
            </label>
            <div
              className="flex flex-row justify-between h-11 w-64"
              style={{ alignItems: "center", border: "1px solid #897143" }}
            >
              <span
                className="w-20 mx-2"
                onClick={this.nextForgetPassword.bind(this)}
                style={{ fontSize: "10px", color: "#897143" }}
              >
                修改密码
              </span>
              <img className="w-6 mx-2" src={leftArrow} alt="" />
            </div>
          </div>

          <div className="flex flex-row my-4" style={{ alignItems: "center" }}>
            <label
              htmlFor="iphone"
              className="w-16 mr-4"
              style={{ fontSize: "12px" }}
            >
              手机号
            </label>
            <div
              className="flex flex-row justify-between h-11 w-64"
              style={{ alignItems: "center", border: "1px solid #897143" }}
            >
              {/* <span
                className="w-20 mx-2"
                style={{ fontSize: "10px", color: "#897143" }}
              >
                更改号码
              </span> */}
              <span></span>
              <span
                className="mx-2"
                style={{ color: "#333333", fontSize: "12px" }}
              >
                18516141213
              </span>
              {/* <img className="mx-2" src={leftArrow} alt="" /> */}
            </div>
          </div>
        </div>
      );
    } else if (val == 4) {
      return (
        <div className="allOrder">
          <div
            className="flex flex-col px-10"
            style={{ backgroundColor: "white" }}
          >
            <div className="flex flex-row justify-center my-4">
              <span
                className="px-8 py-2"
                style={{
                  backgroundColor: "#897143",
                  color: "#FFFFFF",
                  border: "1px solid #f0f0f0",
                  fontSize: "12px",
                }}
              >
                全部
              </span>
              <span
                className="px-8 py-2"
                style={{
                  border: "1px solid #f0f0f0",
                  color: "#999999",
                  fontSize: "12px",
                }}
              >
                待确认
              </span>
              <span
                className="px-8 py-2"
                style={{
                  border: "1px solid #f0f0f0",
                  color: "#999999",
                  fontSize: "12px",
                }}
              >
                已确认
              </span>
            </div>
            {orderList.map((item, index) => (
              <div
                className="flex flex-row my-2"
                key={index}
                onClick={this.nextDetailItem.bind(this, item)}
                // onClick={this.setActive.bind(this, "5")}
              >
                <img className="w-40" src={item.product_pic} alt="" />
                <div className="flex flex-col justify-around px-8 w-full">
                  <p className="flex flex-col justify-around">
                    <span style={{ color: "#453A2C" }}>
                      {item.product_name}
                    </span>

                    <div>
                      <span
                        className="my-4"
                        style={{ color: "#666666", fontSize: "12px" }}
                      >
                        {item.product_info}
                      </span>
                      <br />
                      <span
                        className="my-4"
                        style={{ color: "#666666", fontSize: "12px" }}
                      >
                        {item.start_time}至 {item.end_time}
                      </span>
                    </div>
                  </p>

                  <p
                    className="flex flex-row justify-between"
                    style={{
                      color: "#666666",
                      fontSize: "12px",
                      alignItems: "center",
                    }}
                  >
                    {item.order_status == "0" ? (
                      <span style={{ color: "#D45B54" }}>待确认</span>
                    ) : (
                      <span style={{ color: "#D45B54" }}>待确认</span>
                    )}
                    {item.order_status == "0" ? (
                      <button
                        className="px-10 py-1"
                        style={{
                          border: "1px solid #333333",
                          color: "#333333",
                          fontSize: "12px",
                        }}
                        onClick={this.cancelReservation.bind(this, item)}
                      >
                        取消预定
                      </button>
                    ) : (
                      <button
                        className="px-10 py-1"
                        style={{
                          border: "1px solid #333333",
                          color: "#333333",
                          fontSize: "12px",
                        }}
                        onClick={this.againReservation.bind(this, item)}
                      >
                        再次预定
                      </button>
                    )}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      );
    } else if (val == 5) {
      return (
        <div className="orderDetail">
          <OrderDetail></OrderDetail>
        </div>
      );
    }
  }

  render() {
    return (
      <div className="MyInfo">
        {/* <Header /> */}

        <div
          className="MyInfo_content flex flex-row justify-center  py-4"
          style={{ width: "80%", margin: "auto", height: "600px" }}
        >
          <div className="flex flex-col" style={{ backgroundColor: "#FBFBFC" }}>
            <p
              className="flex flex-row py-3 px-4 justify-center"
              style={{
                alignItems: "center",
                width: "200px",
                backgroundColor: "#897143",
              }}
              onClick={this.setActive.bind(this, "1")}
            >
              <img className="w-6 h-6" src={mine} alt="" />
              <span style={{ color: "white", fontSize: "12px" }}>个人信息</span>
            </p>
            <p
              className="flex flex-row py-3 px-4 justify-center"
              style={{ alignItems: "center", fontSize: "12px" }}
              onClick={this.setActive.bind(this, "2")}
            >
              <img className="w-6 h-6" src={mine} alt="" />
              <span style={{ color: "#333333" }}>我的头像</span>
            </p>
            <p
              className="flex flex-row py-3 px-4 justify-center"
              style={{ alignItems: "center", fontSize: "12px" }}
              onClick={this.setActive.bind(this, "3")}
            >
              <img className="w-6 h-6" src={mine} alt="" />
              <span style={{ color: "#333333" }}>账号安全</span>
            </p>
            <p
              className="flex flex-row py-3 px-4 justify-center"
              style={{ alignItems: "center", fontSize: "12px" }}
              onClick={this.setActive.bind(this, "4")}
            >
              <img className="w-6 h-6" src={mine} alt="" />
              <span style={{ color: "#333333" }}>全部订单</span>
            </p>
          </div>
          <div className="py-4 px-8 overflow-y-auto" style={{ width: "100%" }}>
            {this.ifActiveTypeHtml(this.state.type)}
          </div>
        </div>

        {/* <Footer /> */}
      </div>
    );
  }
}

export default MyInfo;
