import React from "react";
import axios from "axios";
import "./index.less";

import "tailwindcss/tailwind.css";

import orderBg from "../../assets/images/Group477@2x.png";
import edit from "../../assets/images/icon_edit@2x.png";



class MyOrder extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      userInfo: {},
      orderList: [],
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    axios({
      method: "get",
      url: `/Index/user/index?token=${window.localStorage.getItem(
        "loginInfoToken"
      )}`,
    }).then((res) => {
      console.log(res, "12212");
      this.setState({
        userInfo: res.data.data.userInfo,
        orderList: res.data.data.orderList,
      });
    });
  }

  cancelReservation(item: any) {
    axios({
      method: "get",
      url: `/Index/user/cancelOrder?token=${window.localStorage.getItem(
        "loginInfoToken"
      )}&order_sn=${item.order_sn}`,
    }).then((res) => {
      console.log(res, "12212");
    });
  }
  againReservation(item: any) {
    // 再次预定
    this.props.history.push("/Login");
  }

  nextDetailItem(item: any) {
    // 详情
    // this.props.history.push("/Login"); 
  }

  render() {
    let { userInfo, orderList } = this.state;
    return (
      <div className="MyOrder">
        {/* <Header /> */}

        <div className="MyOrder_Content">
          <div
            className="flex flex-col justify-center"
            style={{
              backgroundImage: `url(${orderBg})`,
              backgroundSize: "100% 100%",
              height: "500px",
              alignItems: "center",
            }}
          >
            <div
              className="flex flex-col justify-center"
              style={{ alignItems: "center", marginTop: "-40px" }}
            >
              <div className="w-16 h-16 border-2 my-4 rounded-full overflow-hidden">
                <img className="w-full h-full" src={userInfo.headerimg} alt="" />
              </div>
              <p
                className="flex flex-row justify-center"
                style={{ alignItems: "center" }}
              >
                <span className="text-gray-50 mr-1">{userInfo.nickname}</span>
                <img className="w-6 h-6" src={edit} alt="" />
              </p>
            </div>
          </div>
          <div
            className="flex flex-col mx-40 px-10"
            style={{ backgroundColor: "white", marginTop: "-80px",minHeight: '200px' }}
          >
            <p
              className="my-6"
              style={{ color: "#333333", fontSize: "18px", fontWeight: "500" }}
            >
              我的订单
            </p>
            {orderList.map((item,index) => (
              <div className="flex flex-row my-2" key={index} onClick={this.nextDetailItem.bind(this,item)}>
                <img className="w-80" src={item.product_pic} alt="" />
                <div className="flex flex-col justify-around px-8 w-full">
                  <p className="flex flex-col justify-around">
                    <span style={{ color: "#453A2C" }}>
                      {item.product_name}
                    </span>
                    <span
                      className="my-4"
                      style={{ color: "#666666", fontSize: "12px" }}
                    >
                      {item.product_info}
                    </span>
                  </p>

                  <p
                    className="flex flex-row justify-between"
                    style={{
                      color: "#666666",
                      fontSize: "14px",
                      alignItems: "center",
                    }}
                  >
                    <span>剩余：{item.sales_num} </span>

                    {item.order_status == "0" ? (
                      <button
                        className="px-10 py-1"
                        style={{
                          border: "1px solid #333333",
                          color: "#333333",
                          fontSize: "14px",
                        }}
                        onClick={this.cancelReservation.bind(
                          this,
                          item
                        )}
                      >
                        取消预定
                      </button>
                    ) : (
                      <button
                        className="px-10 py-1"
                        style={{
                          border: "1px solid #333333",
                          color: "#333333",
                          fontSize: "12px",
                        }}
                        onClick={this.againReservation.bind(
                          this,
                          item
                        )}
                      >
                        再次预定
                      </button>
                    )}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* <Footer /> */}
      </div>
    );
  }
}

export default MyOrder;
