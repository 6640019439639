/* This example requires Tailwind CSS v2.0+ */
import React, { useState, useEffect, Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";

import { useHistory } from 'react-router-dom';

import './index.css'

import {
  BookmarkAltIcon,
  CalendarIcon,
  ChartBarIcon,
  CursorClickIcon,
  MenuIcon,
  PhoneIcon,
  PlayIcon,
  RefreshIcon,
  ShieldCheckIcon,
  SupportIcon,
  ViewGridIcon,
  XIcon,
} from "@heroicons/react/outline";

import logoMopbile from "../../assets/images/logo3@3x.png";
import logo from "../../assets/images/logo@2x.png";
import personal from "../../assets/images/icon_personal@2x.png";


export default function Header(props) {
  const [state, setstate] = useState({
    solutions: [
      {
        name: "首页",
        href: "/#/",
        active: true,
      },
      {
        name: "酒店预订",
        href: "/#/HotelReservation",
        active: false,
      },
      {
        name: "酒店介绍",
        href: "/#/HotelIntroduction",
        active: false,
      },
      {
        name: "联系我们",
        href: "/#/ContactUs",
        active: false,
      },
      {
        name: "视频中心",
        href: "/#/VideoList",
        active: false,
      },
      {
        name: "微信公众号推文",
        href: "/#/WxArticle",
        active: false,
      },
    ]
  });

  let { config } = props;

  const setActive = (href) => {
    state.solutions.forEach((ele) => {
      if (ele.href == href) {
        ele.active = true
      } else {
        ele.active = false
      }
    })
    setstate({
      solutions: state.solutions
    })
  }
  const history = useHistory();
  useEffect(() => {

    history.listen(historyLocation => {
      // 每次路由变化都会执行这个方法
      // console.log('route history , ', history);
      // console.log('route history location , ', historyLocation);
      state.solutions.forEach((ele) => {
        if (ele.href == `/${window.location.hash}`) {
          ele.active = true
        } else {
          ele.active = false
        }
      })
      setstate({
        solutions: state.solutions
      })
    })
  }, [])


  return (
    <Popover className="relative bg-theme-color">
      <div
        className="relative bg-theme-color mx-auto "
        style={{ backgroundColor: "#897143" }}
      >
        <div className="flex justify-center items-center py-6 md:justify-center md:space-x-10">
          <div className="flex justify-center lg:w-0 lg:flex-1">
            <a href="#">
              <img
                className="h-20 w-auto sm:h-30"
                src={config.header_logo}
                alt=""
              />
            </a>
          </div>
        </div>
        <div className="flex justify-between items-center pb-2 md:justify-center md:space-x-10">
          {/*<div className="flex justify-start lg:w-0 lg:flex-1">*/}
          {/*  <a href="#"></a>*/}
          {/*</div>*/}
          <div className="hidden md:flex space-x-10">
            {state.solutions.map((item, index) => (
              <a
                key={item.name}
                href={item.href}
                className="p-2 flex items-center flex flex-col hover:bg-gray-0"
                style={{ marginBottom: '-10px', height: '52px' }}
                onClick={() => {
                  setActive(item.href)
                }}
              >
                <span style={{ fontSize: '16px', color: 'white' }} className={` ${item.active ? "activemenu" : null}`}>
                  {item.name}
                </span>
                {item.active ? (<span style={{ fontSize: '24px', marginTop: '-10px', marginBottom: '-14px', color: 'white' }}>·</span>) : (null)}
              </a>
            ))}
          </div>
        </div>
      </div>
    </Popover>
  );
}
