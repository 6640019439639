import React from "react";
import axios from "axios";
import "./index.less";

import "tailwindcss/tailwind.css";
import CardItem from "../../components/CardItem";

import Contact from "../../components/Contact";

class HotelIntroduction extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      introductionConfig: {},
      aboutInfo: {},
      specList:[],
      jdImg:[],
      fxImg:[],
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.initData();
  }

  initData() {
    axios({
      method: "post",
      url: "/Index/index/about",
      data: {},
    }).then((res) => {
      console.log(res, "121221");
      this.setState({
        introductionConfig: res.data.data,

        aboutInfo: res.data.data.aboutInfo,
        specList:res.data.data.specList,
        jdImg:res.data.data.jdImg,
        fxImg:res.data.data.fxImg,
      });
    });
  }

  getWidth(val){
    return `${val * 310}px`
  }

  render() {
    let { about_content, jd_content } = this.state.aboutInfo;
    let { aboutInfo, specList,jdImg, fxImg} = this.state;

    return (
      <div className="HotelIntroduction">
        {/* <Header /> */}
        <div className="HotelIntroduction_content">
          <div>
            <div style={{ backgroundColor: '#FFFFFF', boxShadow: '0px 2px 12px 0px rgba(0,0,0,0.08)' }}>
              <p
                style={{ color: "#897143", fontSize: "24px", fontWeight: "600" }}
                className="py-10 text-center"
              >
                房型介绍
            </p>
              {/* <div className="max-w-2xl mx-auto py-8 px-4 sm:py-10 sm:px-6 lg:max-w-7xl lg:px-8">
                <div className="grid grid-cols-1 gap-y-10 sm:grid-cols-2 gap-x-6 lg:grid-cols-5 xl:grid-cols-8 xl:gap-x-8">
                  {(fxImg && fxImg.length > 0 ? fxImg : []).map((item, index) => (
                    <div
                      key={index}
                      className="w-full aspect-w-1 aspect-h-1 bg-gray-200 overflow-hidden xl:aspect-w-7 xl:aspect-h-8"
                    >
                      <img
                        src={item.pic}
                        alt=""
                        className="w-full h-full object-center object-cover group-hover:opacity-75"
                      />
                    </div>
                  ))}
                </div>
              </div> */}
              <div className="mx-auto py-8 w-full" style={{overflowX: 'auto'}}>
                <div className="flex flex-row items-center" style={{'width': this.getWidth(fxImg.length)}}>
                  {(fxImg && fxImg.length > 0 ? fxImg : []).map((item, index) => (
                    <div
                      key={index}
                      className="w-full bg-gray-200 overflow-hidden mr-2"
                      style={{ width: '300px' }}
                      onClick={()=>{
                        window.location.href = item.link_url
                      }}
                    >
                      <img
                        src={item.pic}
                        alt=""
                        className="w-full h-full object-center object-cover group-hover:opacity-75"
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="space-y-4" style={{ backgroundColor: '#FBFBFC' }}>
              <div
                className="flow-root px-4 py-5 sm:px-6"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div className="text-gray-500" style={{ width: '80%' }}>
                  {/* {about_content} */}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: about_content,
                    }}
                  ></div>
                </div>
              </div>
              <div className="flow-root py-10">
                <div className="w-full px-40">
                  <CardItem specList={specList}></CardItem>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div style={{ backgroundColor: '#FFFFFF', boxShadow: '0px 2px 12px 0px rgba(0,0,0,0.08)' }}>
              <p
                style={{ color: "#897143", fontSize: "24px", fontWeight: "600" }}
                className="py-10 text-center"
              >
                酒店介绍
            </p>
              {/* <div className="mx-auto py-8 px-4 sm:py-10 sm:px-6 lg:px-8">
                <div className="w-full flex flex-row items-center " style={{ justifyContent: 'space-between' }}>
                  {(jdImg && jdImg.length > 0 ? jdImg : []).map((item, index) => (
                    <div
                      key={index}
                      className="w-full bg-gray-200"
                      // style={{ width: '24.5%' }}
                    >
                      <img
                        src={item.pic}
                        alt=""
                        className="w-full h-full object-center object-cover group-hover:opacity-75"
                      />
                    </div>
                  ))}
                </div>
              </div> */}
              <div className="mx-auto py-8 w-full" style={{overflowX: 'auto'}}>
                <div className="flex flex-row items-center" style={{'width':this.getWidth(jdImg.length)}}>
                  {(jdImg && jdImg.length > 0 ? jdImg : []).map((item, index) => (
                    <div
                      key={index}
                      className="w-full bg-gray-200 overflow-hidden mr-2"
                      style={{ width: '300px' }}
                      onClick={()=>{
                        window.location.href = item.link_url
                      }}
                    >
                      <img
                        src={item.pic}
                        alt=""
                        className="w-full h-full object-center object-cover group-hover:opacity-75"
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="space-y-4 mb-16" style={{ backgroundColor: '#FBFBFC' }}>
              <div
                className="flow-root px-4 py-5 sm:px-6"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div className="text-gray-500" style={{ width: '80%' }}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: jd_content,
                    }}
                  ></div>
                </div>
              </div>
              <div className="flow-root py-10">
                <div className="w-full px-40">
                  <CardItem specList={specList}></CardItem>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <Address /> */}

        <Contact />

        {/* <Footer /> */}
      </div>
    );
  }
}

export default HotelIntroduction;
