import React from "react";
import axios from "axios";
import "./index.less";

// import Header from "../../components/Header";
// import Footer from "../../components/Footer";
import logobg from "../../assets/images/unsplash_DGa0LQ0yDPc@2x.png";

class Login extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
    
    };
  }

  componentDidMount(){
    window.scrollTo(0, 0);
    console.log(this.props)
  }

  signIn() {
    axios({
      method: "post",
      url: "/Index/login/login",
      data: {
        phone: (this.refs.iphone as any).value,
        password: (this.refs.password as any).value,
      },
    }).then((res) => {
      if(res.data.code == '200'){
        window.localStorage.setItem('loginStatus','true')
        window.localStorage.setItem('loginInfoToken',res.data.data.token)
        this.props.history.push('/')
      }else{
        window.localStorage.clear()
      }
    });
  }


  render() {
    return (
      <div className="Home">
        {/* <Header /> */}
        <div
          style={{
            backgroundImage: `url(${logobg})`,
            backgroundSize: "100% 100%",
            height: "500px",
            alignItems: "center",
            backgroundAttachment: "local",
          }}
        >
          <div
            style={{ background: "rgba(0,0,0,.4)" }}
            className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 group-hover:opacity-75"
          >
            <div className="space-y-6 w-64">
              <div>
                <h2 className="mt-6 text-center text-2xl font-extrabold text-gray-100">
                  用户登录
                </h2>
              </div>
              <div>
                <label htmlFor="iphone" className="sr-only">
                  iphone
                </label>
                <input
                  id="iphone"
                  name="iphone"
                  type="text"
                  autoComplete="off"
                  ref="iphone"
                  style={{
                    backgroundColor: "transparent",
                    border: "1px solid #FFFFFF",
                  }}
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-300 text-gray-50 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="请输入手机号"
                />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="off"
                  style={{
                    backgroundColor: "transparent",
                    border: "1px solid #FFFFFF",
                  }}
                  ref="password"
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-300 text-gray-50 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="请输入密码"
                />
              </div>
              <div>
                <button
                  type="submit"
                  style={{
                    backgroundColor: "#897143",
                    color: "white",
                    border: "none",
                    outline: "none",
                    fontSize: "12px",
                  }}
                  onClick={this.signIn.bind(this)}
                  className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  登录
                </button>
              </div>

              <div className="flex items-center justify-between">
                <div className="text-sm">
                  <a
                    href="/#/Register"
                    className="font-medium text-indigo-500 hover:text-indigo-500 text-gray-50"
                  >
                    立即注册
                  </a>
                </div>

                <div className="text-sm">
                  <a
                    href="/#/Forgetpassword"
                    className="font-medium text-indigo-500 hover:text-indigo-500 text-gray-50"
                  >
                    忘记密码？
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <Footer /> */}
      </div>
    );
  }
}

export default Login;
