import React from "react";
import ReactDOM from "react-dom";
import { message } from "antd";
import axios from "axios";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { HashRouter } from 'react-router-dom';
import "./index.css";



window.localStorage.setItem('loginStatus', 'true')
window.localStorage.setItem('loginInfoToken', "ffb4ab953866610713567fa8f8458e2386bd97e3")
axios.interceptors.request.use(
  (config) => {
    console.log(config);
    config.url = `http://api.srgrandhotel.com${config.url}`;
    return config;
  },
  (err) => {
    console.log(err, 'er')
  }
);

if (navigator.userAgent.match(/(iPhone|iPod|Android|ios)/i)) {
  window.location.href = 'http://m.srgrandhotel.com/#/M/'
} else {
  console.log('PC端')
}

//   响应拦截器
axios.interceptors.response.use(
  function (response) {
    if (response.data.code === "603") {
      window.localStorage.clear()
      window.sessionStorage.clear()

      // window.location.href = window.location.origin + '/#/Login'
      // message

    }
    if (response.data.code === "201") {
      message.error(response.data.message);
    }
    return response;
  },
  function (error) {
    if (error.response) {
      const { status } = error.response;
      if (status === 403) {

      } else if (status === 500) {

      }

      return error.response;
    }
    return Promise.reject(error);
  }
);

window.onresize = (e) => {
  console.log(e.target.innerWidth);

};




ReactDOM.render(
  <HashRouter>
    <App />

  </HashRouter>,
  document.getElementById('root')
);

reportWebVitals();