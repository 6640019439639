import React from "react";
import axios from "axios";
import "./index.less";
import { Modal, } from 'antd';
import "tailwindcss/tailwind.css";

import Contact from "../../components/Contact";

import contentbg from "../../assets/images/unsplash_DGa0LQ0yDPc@2x.png";

import videoPlay from "../../assets/images/icon_play@2x.png";
import ditu from "../../assets/images/Rectangle1479@2x.png";
import address from "../../assets/images/icon_l_address@2x.png";
import phone from "../../assets/images/icon_l_phone@2x.png";
const { info } = Modal;
class ContactUs extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      contactUsConfig: {},
      homeConfig: {},
      imgListOne: {},
      imgListTwo: {},
      imgListThree: {},
      imgListFour: {},
      imgListFive: {},
      imgListSex: {},
      imgListSeven: {},
      imgListEight: {},
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.initData();
  }
  initData() {
    axios({
      method: "post",
      url: "/Index/index/index",
      data: {},
    }).then((res) => {
      console.log(res);
      this.setState({
        homeConfig: res.data.data,
      });
    });
    axios({
      method: "post",
      url: "/Index/index/contact",
      data: {},
    }).then((res) => {
      this.setState({
        contactUsConfig: res.data.data,
        imgList: res.data.data.imgList,
        imgListOne: res.data.data.imgList[0],
        imgListTwo: res.data.data.imgList[1],
        imgListThree: res.data.data.imgList[2],
        imgListFour: res.data.data.imgList[3],
        imgListFive: res.data.data.imgList[4],
        imgListSex: res.data.data.imgList[5],
        imgListSeven: res.data.data.imgList[6],
        imgListEight: res.data.data.imgList[7],
      });
    });
  }
  showModal = () => {
    info({
      closable: true,
      width: 800,
      icon: null,
      okText: '确定',
      content: (<div>
        <video
          src={this.state.contactUsConfig.contact_video}
          className="w-full"
          style={{
            height: "400px",
          }}
          autoPlay
          controls
        ></video>
      </div>),
    });
  }


  render() {
    let { contact_video, contact_video_img, imgList, } = this.state.contactUsConfig;
    let { homeConfig, imgListOne, imgListTwo, imgListThree, imgListFour, imgListFive, imgListSex, imgListSeven, imgListEight } = this.state;
    return (
      <div className="ContactUs">
        <div className="ContactUs_content" style={{ 'position': 'relative' }}>
          {/*  */}
          <div
            className="flex align-center justify-center relative items-center"
            style={{
              height: "500px",
            }}
          >

            <img src={contact_video_img} className="w-full" style={{
              height: "500px",
            }} alt="" />
            <div className="absolute right-0 left-0 top-0 flex  h-full items-center justify-center">
              <img className="w-16 h-16" src={videoPlay} onClick={this.showModal} alt="" />
            </div>
          </div>
          {/*  */}
          <div className="flex flex-row flex-wrap items-center justify-center" style={{
            'position': 'absolute',
            'bottom': '630px',
            'width': '100%'
          }}>
            <div
              className="inset-y-0 mx-auto w-full"
            >

              <div className="flex flex-row items-start  justify-center" style={{ width: '70%', margin: 'auto' }}>
                <div className="flex flex-col items-center justify-center mr-2" >
                  <div className="w-44 h-64 overflow-hidden sm:opacity-0 lg:opacity-100 mb-2" onClick={() => {
                    console.log('1221s')
                    this.props.history.push('/HotelReservation')
                  }}>
                    <img
                      src={imgListOne.pic}
                      alt=""
                      className="w-full h-full object-center object-cover"

                    />
                  </div>
                  <div className="w-44 overflow-hidden sm:opacity-0 lg:opacity-100">
                    <img
                      src={imgListTwo.pic}
                      alt=""
                      className="w-full h-full object-center object-cover"
                      onClick={() => {
                        this.props.history.push('/HotelReservation')
                      }}
                    />
                  </div>
                </div>
                <div className="flex flex-col items-center justify-center mr-2" >
                  <div className="w-44 h-48 overflow-hidden sm:opacity-0 lg:opacity-100 mb-2">
                    <img
                      src={imgListThree.pic}
                      alt=""
                      className="w-full h-full object-center object-cover"
                      onClick={() => {
                        this.props.history.push('/HotelReservation')
                      }}
                    />
                  </div>
                  <div className="w-44 h-48 overflow-hidden sm:opacity-0 lg:opacity-100">
                    <img
                      src={imgListFour.pic}
                      alt=""
                      className="w-full h-full object-center object-cover"
                      onClick={() => {
                        this.props.history.push('/HotelReservation')
                      }}
                    />
                  </div>
                </div>

                <div className="flex flex-col items-center justify-center mr-2" >
                  <div className="w-44 overflow-hidden sm:opacity-0 lg:opacity-100 mb-2">
                    <img
                      src={imgListFive.pic}
                      alt=""
                      className="w-full h-full object-center object-cover"
                      onClick={() => {
                        this.props.history.push('/HotelReservation')
                      }}
                    />
                  </div>
                  <div className="w-44 h-64 overflow-hidden sm:opacity-0 lg:opacity-100">
                    <img
                      src={imgListSex.pic}
                      alt=""
                      className="w-full h-full object-center object-cover"
                      onClick={() => {
                        this.props.history.push('/HotelReservation')
                      }}
                    />
                  </div>
                </div>
                <div className="flex flex-col items-center justify-center mr-2" >
                  <div className="w-44 h-64 overflow-hidden sm:opacity-0 lg:opacity-100 mb-2">
                    <img
                      src={imgListSeven.pic}
                      alt=""
                      className="w-full h-full object-center object-cover"
                      onClick={() => {
                        this.props.history.push('/HotelReservation')
                      }}
                    />
                  </div>
                  <div className="w-44 h-48 overflow-hidden sm:opacity-0 lg:opacity-100">
                    <img
                      src={imgListEight.pic}
                      alt=""
                      className="w-full h-full object-center object-cover"
                      onClick={() => {
                        this.props.history.push('/HotelReservation')
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*  */}
          <div
            className="flex align-center justify-center"
            style={{
              backgroundImage: `url(${ditu})`,
              backgroundSize: "100% 100%",
              height: "500px",
              alignItems: "center",
              marginTop: '550px'
            }}
          >
            <div id="map2_container">
              <div id="allmap2"></div>
            </div>
            <div
              className="py-2 px-8 w-96 flex flex-col justify-center"
              style={{ backgroundColor: "white", alignItems: "center" }}
            >
              <img style={{}} className="w-14 h-14 my-6" src={address} alt="" />
              <p style={{ color: "#453A2C" }}>上饶国际大酒店</p>
              <p style={{ color: "#333333", fontSize: "10px" }}>
                <span>{homeConfig.address} </span>
              </p>
              <p
                style={{
                  color: "#333333",
                  fontSize: "13px",
                  alignItems: "center",
                }}
                className="flex flex-row py-6"
              >
                <img className="w-4 h-4 mx-2" src={phone} alt="" />
                <span>
                  {" "}
                  <span>{homeConfig.service_phone}</span>
                </span>
              </p>
            </div>
          </div>
        </div>

        <Contact />
        {/* <Footer /> */}
      </div>
    );
  }
}

export default ContactUs;
